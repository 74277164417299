import { RouteObject } from 'react-router-dom';
import { getLocalStorageDistrictId } from '../_core/utils';
import { DistrictRoutes } from './constants';
import { DistrictDashboardPage } from './pages/district-dashboard/district-dashboard';
import { FamiliesListDistrictLevelPage } from '../family/pages/family-list/family-list-district-level.page';
import { FamilyDetailsPage } from '../family/pages/family-details/family-details.page';
import { ProvidersListPage } from '../providers/pages/providers-level-district/providers-list.page';
import { CustomRequirementsListPage } from '../custom-requirements/pages/custom-requirements-list.page';
import { UsersListPage } from '../user-managment/pages/users-list/users-list.page';
import { ProfileRoutes } from '../profile/constants';
import { ProvidersDetailsPage } from '../providers/pages/providers-details/providers-details.page';
import { EngagementsListDistrictLevelPage } from '../engagement/pages/engagements-list/engagements-list-district-level.page';
import { EngagementDetailsPage } from '../engagement/pages/engagements-details/engagements-details.page';

export const DistrictAdminModuleRouter: RouteObject[] = [
	{
		path: DistrictRoutes.CustomRequirements,
		element: <CustomRequirementsListPage />,
		handle: { title: 'headers.custom-requirements' },
	},
	{ path: DistrictRoutes.UserManagment, element: <UsersListPage />, handle: { title: 'headers.users' } },
];

export const DistrictModuleRouter: RouteObject[] = [
	{
		path: DistrictRoutes.Dashboard,
		element: <DistrictDashboardPage />,
		handle: { title: 'nav.dashboard', needsPaper: false },
	},
	{
		path: DistrictRoutes.Families.Root,
		element: <FamiliesListDistrictLevelPage />,
		handle: { title: 'headers.families' },
	},
	{
		path: DistrictRoutes.Families.Detailes,
		element: <FamilyDetailsPage />,
		handle: {
			title: 'headers.family-details',
			needsPaper: false,
			to: {
				path: getLocalStorageDistrictId()
					? DistrictRoutes.Families.Root.replace(':districtId', getLocalStorageDistrictId() || '')
					: ProfileRoutes.Families,
			},
		},
	},
	{
		path: DistrictRoutes.Engagements.Root,
		element: <EngagementsListDistrictLevelPage />,
		handle: { title: 'headers.engagements' },
	},
	{ path: DistrictRoutes.Providers.Root, element: <ProvidersListPage />, handle: { title: 'headers.providers' } },
	{
		path: DistrictRoutes.Providers.Details,
		element: <ProvidersDetailsPage />,
		handle: {
			title: 'headers.providers-details',
			needsPaper: false,
			to: {
				path: getLocalStorageDistrictId()
					? DistrictRoutes.Providers.Root.replace(':districtId', getLocalStorageDistrictId() || '')
					: ProfileRoutes.Providers,
			},
		},
	},

	{
		path: DistrictRoutes.Engagements.Details,
		element: <EngagementDetailsPage />,
		handle: {
			title: 'headers.engagement-details',
			needsPaper: false,
			to: {
				path: getLocalStorageDistrictId()
					? DistrictRoutes.Engagements.Root.replace(':districtId', getLocalStorageDistrictId() || '')
					: ProfileRoutes.Engagements,
			},
		},
	},
];
