import { LanguageEnum, LanguageToCountryCodeEnum } from '@families-link/shared';
import { Box } from '@mui/material';
import Flag from 'react-flagkit';
import { InternationalFlag } from '../../constants/icons.constants';

interface LanguageOption {
	code: string;
	value: string;
	name: string;
	label: JSX.Element;
}

export const LanguageOptions: LanguageOption[] = Object.keys(LanguageEnum).map((key) => {
	const languageKey = key as keyof typeof LanguageEnum;
	const countryCode = LanguageToCountryCodeEnum[languageKey];
	const languageName = LanguageEnum[languageKey];

	return {
		code: countryCode,
		name: languageName,
		value: languageKey,
		label: (
			<Box style={{ display: 'flex', alignItems: 'center' }}>
				{countryCode ? (
					<Flag country={countryCode} style={{ marginRight: '8px' }} />
				) : (
					<InternationalFlag style={{ width: '24px', height: '24px', marginRight: '8px' }} />
				)}
				{languageName}
			</Box>
		),
	};
});

export const getObjectLanguage = (valueName?: string) => LanguageOptions.find((el) => el.name === valueName);
export const getObjectLanguageByKey = (valueKey?: string) => LanguageOptions.find((el) => el.value === valueKey);
