import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useMuiModal } from '../../../_core/hooks';
import { ConfirmationModal, ConfirmationModalProps } from '../../../_core/components/confirmation-modal';
import { CreateFamilyDto, FamilyDto, UpdateFamilyDto } from '@families-link/shared';

export type useOpenConfirmModalFormHookProps = {
	featchAction(values: CreateFamilyDto | UpdateFamilyDto): Promise<void>;
	handlePagination(): void;
	title: string;
	description?: string;
	icon?: ReactNode;
	danger?: boolean;
	submitButtonName?: string;
	toastText?: string;
};

export function useOpenConfirmModal(props: useOpenConfirmModalFormHookProps) {
	const {
		featchAction,
		handlePagination,
		title,
		description,
		icon,
		danger = false,
		submitButtonName,
		toastText,
	} = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const showToast = (message: string, variant: 'error' | 'default' | 'success' | 'warning' | 'info' | undefined) => {
		enqueueSnackbar(message, {
			variant,
		});
	};

	const { openModal, closeModal } = useMuiModal<ConfirmationModalProps>(ConfirmationModal);
	const handleConfirm = async (value: CreateFamilyDto | UpdateFamilyDto): Promise<void> => {
		try {
			await featchAction(value);
			showToast(toastText || 'Successfully performed', 'success');
			handlePagination();
			closeModal();
		} catch (error) {
			console.error(error);
		}
	};

	const handleOpenConfirmModal = useCallback(
		(value: FamilyDto) => {
			const handleSubmit = async () => {
				await handleConfirm(value);
			};
			openModal({
				title,
				description: description?.replace(':familyName', value.fullName),
				onSubmit: handleSubmit,
				onClose: closeModal,
				icon,
				danger,
				leftButtonProps: {
					children: t('families.forms.buttons.cancel'),
					variant: 'secondary',
				},
				rightButtonProps: {
					children: submitButtonName,
					variant: danger ? 'danger' : 'info',
				},
			});
		},
		[handleConfirm, openModal]
	);

	return handleOpenConfirmModal;
}
