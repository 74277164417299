import React, { FC, useCallback, useMemo } from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { ProviderDto } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { CellAction, CoreTableActionsCell } from '../../../_core/components/_ui/core-table';

interface ProvidersTableActionsProps {
	provider: ProviderDto;
	onView(provider: ProviderDto): void;
	onDelete(provider: ProviderDto): void;
	onEdit(provider: ProviderDto): void;
}

export const ProvidersTableActions: FC<ProvidersTableActionsProps> = (props) => {
	const { provider, onDelete, onEdit, onView } = props;

	const [t] = useTranslation();

	const handleDeleteProvider = useCallback(() => {
		if (!provider) {
			return;
		}

		onDelete?.(provider);
	}, [onDelete, provider]);

	const handleEditProvider = useCallback(() => {
		if (!provider) {
			return;
		}

		onEdit?.(provider);
	}, [onEdit, provider]);

	const handleViewProvider = useCallback(() => {
		if (!provider) {
			return;
		}

		onView?.(provider);
	}, [onView, provider]);

	const actions = useMemo<CellAction[]>(
		() => [
			{
				id: 'view',
				type: 'button',
				props: {
					children: <RemoveRedEyeIcon color="primary" />,
					onClick: handleViewProvider,
				},
			},
			{
				id: 'more',
				type: 'menu',
				props: {
					options: [
						{
							id: 'edit',
							label: t('providers.edit'),
							icon: <DriveFileRenameOutlineOutlinedIcon color="primary" />,
							onClick: handleEditProvider,
						},
						{
							id: 'delete',
							label: t('providers.delete'),
							icon: <DeleteOutlineOutlinedIcon color="primary" />,
							onClick: handleDeleteProvider,
						},
					],
				},
			},
		],
		[t, handleViewProvider, handleEditProvider, handleDeleteProvider]
	);

	return <CoreTableActionsCell actions={actions} />;
};
