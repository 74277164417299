import {
	FamilyDto,
	PageDto,
	SourceFilter,
	PageOptionsDto,
	LanguageFilter,
	DistrictFilter,
} from '@families-link/shared';
import api from '.';

const familiesApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getFamiliesList: builder.query<
			PageDto<FamilyDto>,
			PageOptionsDto & SourceFilter & { district?: string | null } & LanguageFilter & DistrictFilter
		>({
			query: ({ page, take, orderField, order, source, district, languages, districts }) => ({
				url: `families`,
				params: { page, take, orderField, order, source, district, languages, districts },
			}),
		}),
		getAllFamiliesByDistrictId: builder.query<FamilyDto[], { district?: string | null }>({
			query: ({ district }) => ({
				url: `families/by/district`,
				params: { district },
			}),
		}),
		getFamily: builder.query<FamilyDto, { id?: string | null }>({
			query: ({ id }) => ({
				url: `families/${id}`,
			}),
		}),
		createFamily: builder.mutation<FamilyDto, FormData>({
			query: (data) => ({
				url: `families`,
				method: 'POST',
				body: data,
			}),
		}),
		updateFamily: builder.mutation<FamilyDto, { id?: string; data: FormData }>({
			query: ({ id, data }) => ({
				url: `families/${id}`,
				method: 'PUT',
				body: data,
			}),
		}),
		deleteFamily: builder.mutation<FamilyDto, { id: string }>({
			query: ({ id }) => ({
				url: `families/${id}`,
				method: 'DELETE',
			}),
		}),
	}),
});

export default familiesApi;

export const {
	useGetFamiliesListQuery,
	useGetAllFamiliesByDistrictIdQuery,
	useGetFamilyQuery,
	useCreateFamilyMutation,
	useUpdateFamilyMutation,
	useDeleteFamilyMutation,
} = familiesApi;
