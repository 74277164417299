import { UserDto } from '@families-link/shared';
import { FC, useCallback, useMemo } from 'react';
import { CoreTableActionsCell, CellAction } from '../../../_core/components/_ui/core-table';
import { UsersTableHandlers } from './users-table.component';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import MarkAsUnreadOutlinedIcon from '@mui/icons-material/MarkAsUnreadOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import { Edit, SendIcon } from '../../../_core/constants/icons.constants';
export interface UsersTableActionsProps extends UsersTableHandlers {
	user: UserDto;
}

export const UsersTableActions: FC<UsersTableActionsProps> = (props) => {
	const { user, onEdit, onDelete, onMailSend, onResetPassword } = props;

	const handleDeleteUser = useCallback(() => {
		if (!user) {
			return;
		}

		onDelete?.(user);
	}, [onDelete, user]);

	const handleEditUser = useCallback(() => {
		if (!user) {
			return;
		}

		onEdit?.(user);
	}, [onEdit, user]);

	const handleMailSend = useCallback(() => {
		if (!user) {
			return;
		}

		onMailSend?.(user);
	}, [onMailSend, user]);

	const handleResetPassword = useCallback(() => {
		if (!user) {
			return;
		}

		onResetPassword?.(user);
	}, [onResetPassword, user]);

	const actions = useMemo<CellAction[]>(
		() => [
			{
				id: 'edit',
				type: 'button',
				props: {
					children: <Edit color="primary" />,
					onClick: handleEditUser,
				},
			},
			{
				id: 'mail',
				type: 'button',
				props: {
					children: <SendIcon color="primary" />,
					onClick: handleMailSend,
				},
			},
			{
				id: 'more',
				type: 'menu',
				props: {
					options: [
						{
							id: 'reetPassword',
							label: 'Reset password',
							icon: <RestartAltOutlinedIcon color="primary" />,
							onClick: handleResetPassword,
						},
						{
							id: 'delete',
							label: 'Delete',
							icon: <DeleteOutlineOutlinedIcon color="primary" />,
							onClick: handleDeleteUser,
						},
					],
				},
			},
		],
		[handleEditUser, handleMailSend, handleResetPassword, handleDeleteUser]
	);

	return <CoreTableActionsCell actions={actions} />;
};
