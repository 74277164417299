import React, { ElementType, FC, forwardRef, RefAttributes, useRef } from 'react';
import { CoreFormControl, CoreFormControlProps } from '../core-form-control';
import { InputBaseProps, Select, SelectProps, SxProps, Theme } from '@mui/material';
import { selectPaperStyles } from './core-select.styles';
import { DropdownArrow } from './dropdown-arrow.component';
import { CoreInput, CoreInputProps } from '../core-input';

export interface CoreSelectProps extends CoreFormControlProps, InputBaseProps, CoreInputProps {
	SelectProps?: SelectProps;
	IconComponent?: ElementType;
	disableIconRotate?: boolean;
	iconSize?: number;
	paperSx?: SxProps<Theme>;
	fitMenuWidth?: boolean;
	menuMinWidth?: number;
	onClose?(): void;
	renderLabel?: (selected: unknown) => any;
}

export const CoreSelect: FC<Omit<CoreSelectProps, 'ref'> & RefAttributes<unknown>> = forwardRef((props, ref) => {
	const {
		menuMinWidth,
		children,
		focused,
		helperText,
		error,
		small,
		controlSx,
		SelectProps,
		disabled,
		fullWidth,
		label,
		labelHint,
		requiredMark,
		id,
		IconComponent,
		disableIconRotate,
		iconSize = 16,
		onClose,
		paperSx,
		fitMenuWidth,
		placeholder,
		renderLabel,
		...inputProps
	} = props;

	const selectContainerRef = useRef<HTMLDivElement | null>(null);
	const offsetWidth = selectContainerRef.current?.offsetWidth;

	const inputHeight = small ? 36 : 48;

	const getLabelFromValue = (value: any, children: React.ReactNode) =>
		React.Children.toArray(children)
			.filter((child): child is React.ReactElement => React.isValidElement(child))
			.find((child) => child.props.value === value)?.props.children || value;

	return (
		<CoreFormControl
			label={label}
			labelHint={labelHint}
			id={id}
			requiredMark={requiredMark}
			fullWidth={fullWidth}
			focused={focused}
			controlSx={controlSx}
			helperText={helperText}
			error={error}
			disabled={disabled}
		>
			<Select
				{...SelectProps}
				ref={selectContainerRef}
				sx={{
					'& .MuiSelect-icon': {
						right: small ? 12 : 16,
						top: (inputHeight - iconSize) / 2,
						transform: disableIconRotate ? 'none' : undefined,
					},
				}}
				MenuProps={{
					...SelectProps?.MenuProps,
					TransitionProps: {
						onExited: onClose,
					},
					PaperProps: {
						sx: [
							{
								width: fitMenuWidth
									? (menuMinWidth ?? 0) > (offsetWidth ?? 0)
										? menuMinWidth
										: offsetWidth
									: undefined,
							},
							...(Array.isArray(selectPaperStyles) ? selectPaperStyles : [selectPaperStyles]),
							...(Array.isArray(paperSx) ? paperSx : [paperSx]),
						],
					},
				}}
				SelectDisplayProps={{
					style: { paddingRight: small ? 40 : 44 },
				}}
				IconComponent={IconComponent ?? DropdownArrow}
				displayEmpty
				renderValue={(selected) => {
					if (!selected) {
						return <span style={{ color: '#999' }}>{placeholder}</span>;
					}
					if (SelectProps?.renderValue) {
						return SelectProps.renderValue(selected);
					} else {
						return getLabelFromValue(selected, children);
					}
				}}
				input={<CoreInput {...inputProps} ref={ref} small={small} id={id} />}
			>
				{children}
			</Select>
		</CoreFormControl>
	);
});
