import React, { forwardRef } from 'react';
import { CoreFormControl } from '../core-form-control';
import { TimePicker } from '@mui/x-date-pickers';

export const CoreTimeField = forwardRef<unknown, any>((props, ref) => {
	const { id, fullWidth, controlSx, label, helperText, error, disabled, focused, requiredMark, ...inputProps } = props;

	return (
		<CoreFormControl
			disabled={disabled}
			error={error}
			helperText={helperText}
			label={label}
			id={id}
			requiredMark={requiredMark}
			focused={focused}
			fullWidth={fullWidth}
			controlSx={controlSx}
		>
			<TimePicker
				{...inputProps}
				slotProps={{
					layout: {
						sx: {
							'& .MuiMultiSectionDigitalClockSection-root::after': {
								display: 'none',
							},
						},
					},
				}}
			/>
		</CoreFormControl>
	);
});
