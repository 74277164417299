import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { NewPasswordForm } from '../../components/password-form';
import { containerStyles, endTextStyles, wrapperStyles } from './restore-password.styles';
import { useRestorePasswordMutation, useValidateInvitationQuery } from '../../../../store/api/auth.api';
import { AuthRoutes } from '../../constants';
import { linkStyles } from '../../components/login-form/login-form.styles';
import { RestorePasswordDto } from '@families-link/shared';
import jwtDecode from 'jwt-decode';
import { JwtPayloadWithRole } from '../../interfaces/token-with-payload.interface';
import { useGetUserActiveStatusByIdQuery } from '../../../../store/api/users.api';
import { useSnackbar } from 'notistack';

type FormState = {
	activeForm: 'password' | 'end';
	password?: string | null;
};

export const RestorePasswordConfirmPage: FC = () => {
	useTranslation();

	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const { otp, token, tokenPayload } = useMemo(() => {
		const otp = searchParams.get('otp') ?? '';

		const token = searchParams.get('token') ?? '';
		const tokenPayload = jwtDecode<JwtPayloadWithRole>(token);

		return { token, otp, tokenPayload };
	}, [searchParams]);

	const { data: isActiveUser } = useGetUserActiveStatusByIdQuery(tokenPayload?.sub ?? '');
	const { data: validInvitationData, error } = useValidateInvitationQuery({ token }, { skip: !token });
	const [restorePassword, { error: restorePasswordError }] = useRestorePasswordMutation();

	const isValidInvitation = validInvitationData?.isValid ?? !error;

	const match = useMatch({
		path: AuthRoutes.Invitation,
		end: false,
	});

	const [{ activeForm }, setFormState] = useState<FormState>({
		activeForm: 'password',
		password: null,
	});

	const { enqueueSnackbar } = useSnackbar();

	const showToast = (message: string, variant: 'error' | 'default' | 'success' | 'warning' | 'info' | undefined) => {
		enqueueSnackbar(message, {
			variant,
			autoHideDuration: 7000,
		});
	};

	useEffect(() => {
		if (!isValidInvitation) {
			showToast('Link is expired', 'warning');
			navigate(AuthRoutes.LoginEmail);
		}
	}, [isValidInvitation, navigate]);

	useEffect(() => {
		if (match && isActiveUser) {
			navigate(AuthRoutes.LoginEmail);
		}
	}, [navigate, match, isActiveUser]);

	const handlePasswordSubmit = useCallback(
		async ({ password, confirmPassword }: RestorePasswordDto) => {
			if (!token || !otp) return;

			await restorePassword({ password, confirmPassword, token, code: otp });

			setFormState({
				activeForm: 'end',
				password,
			});
		},
		[token, otp, restorePassword]
	);

	if (!token) {
		return null;
	}

	return (
		<Container sx={containerStyles}>
			<Box sx={wrapperStyles}>
				{activeForm === 'password' && <NewPasswordForm error={restorePasswordError} onSubmit={handlePasswordSubmit} />}
				{activeForm === 'end' && (
					<Typography sx={endTextStyles}>
						<Trans
							i18nKey="restore-password.restore-complete"
							components={[
								<Box sx={linkStyles} component={(props) => <Link {...props} to={AuthRoutes.LoginEmail} />} />,
							]}
						/>
					</Typography>
				)}
			</Box>
		</Container>
	);
};
