import React, { FC, useCallback, useMemo } from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { FieldDto } from '@families-link/shared';
import { CellAction, CoreTableActionsCell } from '../../../_core/components/_ui/core-table';

interface CustomRequirementsTableActionsProps {
	field: FieldDto;
	onDelete(field: FieldDto): void;
	onEdit(field: FieldDto): void;
}

export const CustomRequirementsTableActions: FC<CustomRequirementsTableActionsProps> = (props) => {
	const { field, onDelete, onEdit } = props;

	const handleDeleteCustomField = useCallback(() => {
		if (!field) {
			return;
		}

		onDelete?.(field);
	}, [onDelete, field]);

	const handleEditCustomField = useCallback(() => {
		if (!field) {
			return;
		}
		onEdit?.(field);
	}, [onEdit, field]);

	const isDefault = field.attributeType === 'Default';
	const isOptions = field.dataType === 'Options';

	const actions = useMemo<CellAction[]>(
		() => [
			{
				id: 'edit',
				type: 'button',
				props: {
					children: <DriveFileRenameOutlineOutlinedIcon color={isDefault && !isOptions ? 'disabled' : 'primary'} />,
					disabled: isDefault && !isOptions,
					onClick: handleEditCustomField,
				},
			},
			{
				id: 'delete',
				type: 'button',
				props: {
					children: <DeleteOutlineOutlinedIcon color={isDefault ? 'disabled' : 'primary'} />,
					disabled: isDefault,
					onClick: handleDeleteCustomField,
				},
			},
		],
		[handleEditCustomField, handleDeleteCustomField, isDefault]
	);

	return <CoreTableActionsCell actions={actions} />;
};
