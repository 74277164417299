import React, { FC, useEffect } from 'react';
import { FamiliesTable } from '../../components/families-table';
import { Box, Chip, Container, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { useTranslation } from 'react-i18next';
import {
	useGetFamiliesListQuery,
	useCreateFamilyMutation,
	useDeleteFamilyMutation,
	useUpdateFamilyMutation,
} from '../../../../store/api/families.api';
import { FamilyDto, LanguageEnum, PermissionDto, UserRolesEnum } from '@families-link/shared';
import { useNavigate } from 'react-router-dom';
import { DistrictRoutes } from '../../../district/constants';
import {
	useSource,
	useSortingParams,
	usePaginatedData,
	useLanguageFilter,
	useDistrictFilter,
} from '../../../_core/hooks';
import { CoreAutoComplete } from '../../../_core/components/_ui/core-autocomplete';
import { getObjectLanguage } from '../../../_core/components/languages-component';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { useOpenModalForm } from '../../hooks/modal-forms/use-open-modal-form.hook';
import { useOpenConfirmModal } from '../../hooks/modal-forms/use-open-confirm-modal-form.hook';
import { getLocalStorageDistrictId, setLocalStorageFamilyId } from '../../../_core/utils';
import { useCurrentAccessTokenSelector } from '../../../auth';
import { useGetDistrictListWithoutPaginationQuery } from '../../../../store/api/district.api';

export type FamiliesListPageProps = {
	permission?: PermissionDto;
};

export const FamiliesListPage: FC<FamiliesListPageProps> = (props) => {
	const { permission } = props;

	const [t] = useTranslation();
	const navigate = useNavigate();
	const { register, source } = useSource();

	const currentToken = useCurrentAccessTokenSelector();
	const isRequester = currentToken?.payload.sur === UserRolesEnum.Requester;

	const { sortingArray, sortingParams, handleSortingChange } = useSortingParams();
	const { register: registerLang, getValues, control, languages } = useLanguageFilter();
	const { data: districtList } = useGetDistrictListWithoutPaginationQuery();
	const {
		register: registerDistrict,
		getValues: getValuesDistrict,
		control: controlDistrict,
		districts,
	} = useDistrictFilter();

	const take = 15;
	const { isFetching, meta, page, handleResetPage, itemsList, refetch, fetchMore } = usePaginatedData<FamilyDto>(
		useGetFamiliesListQuery,
		{
			take,
			source,
			...sortingParams,
			languages: languages.join(','),
			district: getLocalStorageDistrictId(),
			districts: districts.join(','),
		}
	);

	useEffect(() => {
		handleResetPage();
	}, [sortingArray, source]);

	const handlePagination = () => {
		handleResetPage();
		if (page === 1) {
			refetch();
		}
	};

	const [createFamily] = useCreateFamilyMutation();
	const featchActionCreate = async (data: FormData) => {
		await createFamily(data).unwrap();
	};
	const handleOpenModalCreate = useOpenModalForm({
		featchAction: featchActionCreate,
		handlePagination,
		isCreate: true,
		submitButtonName: t('families.forms.buttons.create'),
		toastText: 'Family contact was successfully created',
	});

	const [updateFamily] = useUpdateFamilyMutation();
	const featchActionEdit = async (data: FormData) => {
		await updateFamily({ id: data.get('_id')?.toString(), data }).unwrap();
	};
	const handleOpenModalEdit = useOpenModalForm({
		featchAction: featchActionEdit,
		handlePagination,
		isCreate: false,
		submitButtonName: t('families.forms.buttons.save'),
		toastText: 'Family contact was successfully updated',
	});

	const [deleteFamily] = useDeleteFamilyMutation();
	const featchActionDelete = async (data: FamilyDto) => {
		await deleteFamily({ id: data._id }).unwrap();
	};
	const handleOpenDeleteConfirmModal = useOpenConfirmModal({
		featchAction: featchActionDelete,
		handlePagination,
		title: t('families.forms.delete-title'),
		description: t('families.forms.delete-description'),
		icon: <DeleteOutlineOutlinedIcon />,
		danger: true,
		submitButtonName: t('families.forms.buttons.delete'),
		toastText: 'Family contact was successfully deleted',
	});

	const handleOnView = (family: FamilyDto) => {
		setLocalStorageFamilyId(family._id);
		navigate(DistrictRoutes.Families.Detailes.replace(':familyId', family._id));
	};

	return (
		<Container
			maxWidth={false}
			sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: '16px' }}
		>
			<Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
				<CoreTextField
					{...register('source')}
					placeholder={t('common.search-by-name')}
					endAdornment={
						<InputAdornment position="end">
							<SearchIcon />
						</InputAdornment>
					}
					sx={{ marginRight: '16px' }}
				/>
				<CoreAutoComplete
					multiple={true}
					{...registerLang('languages')}
					control={control}
					controlSx={{ minWidth: '190px', marginRight: '16px' }}
					IconComponent={<FilterAltOutlinedIcon />}
					options={Object.values(LanguageEnum)}
					getOptionLabel={(option) => {
						const language = getObjectLanguage(option);
						return language ? language.name : '';
					}}
					selectOnFocus={true}
					isOptionEqualToValue={(option, value) => option === value}
					renderInput={(params) => (
						<TextField
							{...params}
							placeholder={!getValues('languages')?.length ? t('common.filter-lang') : ''}
							sx={{
								'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
									padding: '4.5px 4px 5.5px 5px',
									minWidth: 0,
								},
								'& .MuiInputBase-input': {
									height: '100%',
									padding: '0',
								},
							}}
						/>
					)}
					renderOption={(props, option) => {
						const language = getObjectLanguage(option);
						return (
							<MenuItem {...props} key={language?.value}>
								{language?.label}
							</MenuItem>
						);
					}}
					renderTags={(value, getTagProps) => {
						const count = value.length - 1;
						return [
							value[0] && <Chip {...getTagProps({ index: 0 })} label={getObjectLanguage(value[0])?.name} />,
							count > 0 && <Chip {...getTagProps({ index: count })} label={`+${count} more`} />,
						];
					}}
				/>
				{permission?.read ? (
					<CoreAutoComplete
						multiple={true}
						{...registerDistrict('districts')}
						control={controlDistrict}
						controlSx={{ minWidth: '190px' }}
						IconComponent={<FilterAltOutlinedIcon />}
						options={districtList || []}
						getOptionLabel={(option) => option.name || ''}
						selectOnFocus={true}
						isOptionEqualToValue={(option, value) => option._id === value._id}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder={!getValuesDistrict('districts')?.length ? t('common.filter-district') : ''}
								sx={{
									'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
										padding: '4.5px 4px 5.5px 5px',
										minWidth: 0,
									},
									'& .MuiInputBase-input': {
										height: '100%',
										padding: '0',
									},
								}}
							/>
						)}
						renderOption={(props, option) => (
							<MenuItem {...props} key={option._id}>
								<Typography>{option.name}</Typography>
							</MenuItem>
						)}
						renderTags={(value, getTagProps) => {
							const count = value.length - 1;
							return [
								value[0] && <Chip {...getTagProps({ index: 0 })} label={value[0]?.name} />,
								count > 0 && <Chip {...getTagProps({ index: count })} label={`+${count} more`} />,
							];
						}}
					/>
				) : null}
				{!isRequester && permission?.create ? (
					<CoreButton
						onClick={() => {
							handleOpenModalCreate();
						}}
						sx={{ marginLeft: 'auto' }}
					>
						{t('families.add-family')}
					</CoreButton>
				) : null}
			</Box>
			<FamiliesTable
				data={itemsList}
				loading={isFetching}
				hasMore={meta?.hasNextPage}
				onDelete={handleOpenDeleteConfirmModal}
				onEdit={handleOpenModalEdit}
				onView={handleOnView}
				sorting={sortingArray}
				onSortingChange={handleSortingChange}
				fetchMore={fetchMore}
				permission={permission}
			/>
		</Container>
	);
};
