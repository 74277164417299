import { FC, useEffect } from 'react';
import { Box, Grid, Paper } from '@mui/material';
import { useInitiateState, usePaginatedData, useSortingParams } from '../../../_core/hooks';
import { FamilyDetails } from '../../components/family-details/family-details.component';
import { EngagementsTable } from '../../../engagement/components/engagements-table';
import {
	useCreateEngagementMutation,
	useDeleteEngagementMutation,
	useGetEngagementsByFamilyIdQuery,
	useUpdateEngagementMutation,
} from '../../../../store/api/engagements.api';
import { EngagementDto, UpdateEngagementDto } from '@families-link/shared';
import { getLocalStorageDistrictId, getLocalStorageFamilyId } from '../../../_core/utils';

import { useTranslation } from 'react-i18next';
import { useGetAllFieldsQuery } from '../../../../store/api/customRequirements.api';
import { useGetAllFamiliesByDistrictIdQuery, useGetFamilyQuery } from '../../../../store/api/families.api';
import { useGetAllProvidersQuery } from '../../../../store/api/providers.api';
import { useOpenModalForm } from '../../../engagement/hooks/modal-forms/use-open-modal-form.hook';
import { useOpenConfirmModal } from '../../../engagement/hooks/modal-forms/use-open-confirm-modal-form.hook';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import AddIcon from '@mui/icons-material/Add';

export const FamilyDetailsPage: FC = () => {
	useInitiateState('family');

	const family = getLocalStorageFamilyId();
	const { data: currentFamily, error, isLoading } = useGetFamilyQuery({ id: family });

	const currentDistrict = getLocalStorageDistrictId();

	const [t] = useTranslation();
	const { data: fieldsListFromDistrict } = useGetAllFieldsQuery({
		districtId: currentDistrict ?? currentFamily?.districtId ?? '',
	});

	const { data: providersList } = useGetAllProvidersQuery({
		sortBy: undefined,
		sortOrder: undefined,
		searchQuery: undefined,
	});
	const { data: familiesList } = useGetAllFamiliesByDistrictIdQuery({
		district: currentDistrict ?? currentFamily?.districtId,
	});

	const take = 15;
	const { sortingArray, sortingParams, handleSortingChange } = useSortingParams();
	const { isFetching, meta, page, handleResetPage, itemsList, refetch, fetchMore } = usePaginatedData<EngagementDto>(
		useGetEngagementsByFamilyIdQuery,
		{
			take,
			...sortingParams,
			familyId: family,
		}
	);

	useEffect(() => {
		handleResetPage();
	}, [sortingArray]);

	const handlePagination = () => {
		handleResetPage();
		if (page === 1) {
			refetch();
		}
	};

	const [createEngagement] = useCreateEngagementMutation();
	const featchActionCreate = async (data: any) => {
		await createEngagement(data).unwrap();
	};
	const handleOpenModalCreate = useOpenModalForm({
		featchAction: featchActionCreate,
		handlePagination,
		isCreate: true,
		submitButtonName: t('engagements.forms.buttons.create'),
		toastText: 'Engagement record was successfully created',
		fieldsListFromDistrict,
		providersList,
		familiesList: currentFamily ? [currentFamily] : [],
		defaultValues: { family: currentFamily },
	});

	const [updateEngagement] = useUpdateEngagementMutation();
	const featchActionEdit = async (data: any) => {
		await updateEngagement({ id: data._id, data }).unwrap();
	};
	const handleOpenModalEdit = useOpenModalForm({
		featchAction: featchActionEdit,
		handlePagination,
		isCreate: false,
		submitButtonName: t('engagements.forms.buttons.save'),
		toastText: 'Engagement record was successfully updated',
		fieldsListFromDistrict,
		providersList,
		familiesList,
	});

	const [deleteEngagement] = useDeleteEngagementMutation();
	const featchActionDelete = async (data: EngagementDto) => {
		await deleteEngagement({ id: data._id }).unwrap();
	};
	const handleOpenDeleteConfirmModal = useOpenConfirmModal({
		featchAction: featchActionDelete,
		handlePagination,
		title: t('engagements.forms.delete-title'),
		description: t('engagements.forms.delete-description'),
		icon: <DeleteOutlineOutlinedIcon />,
		danger: true,
		submitButtonName: t('engagements.forms.buttons.delete'),
		toastText: 'Engagement record was successfully deleted',
	});

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
			<Paper elevation={0} sx={{ width: '100%', mr: 1, p: 1, height: '32%' }}>
				<FamilyDetails />
			</Paper>
			<Paper
				elevation={0}
				sx={{
					flex: 1,
					p: 3,
					display: 'flex',
					height: '70%',
					mt: 1,
					borderBottomRightRadius: 0,
					borderBottomLeftRadius: 0,
					flexDirection: 'column',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						alignItems: 'flex-end',
						width: '100%',
					}}
				>
					<CoreButton
						onClick={() => {
							handleOpenModalCreate();
						}}
						sx={{
							display: 'flex',
							padding: '12px 24px',
							gap: '12px',
						}}
					>
						<AddIcon /> {t('engagements.add-engagements')}
					</CoreButton>
				</Box>
				<EngagementsTable
					data={itemsList}
					loading={isFetching}
					hasMore={meta?.hasNextPage}
					onDelete={handleOpenDeleteConfirmModal}
					onEdit={handleOpenModalEdit}
					sorting={sortingArray}
					onSortingChange={handleSortingChange}
					fetchMore={fetchMore}
					permission={{ isDetailsPage: true }}
				/>
			</Paper>
		</Box>
	);
};
