import React, { useCallback } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { Box, MenuItem, Grid, TextField, Button, IconButton, Typography } from '@mui/material';
import { CustomFieldInfoDto, CustomFieldDataTypeArray,ServiceTypeStatusArray } from '@families-link/shared';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { useTranslation } from 'react-i18next';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import { CoreSelect } from '../../../_core/components/_ui/core-select';
import CloseIcon from '@mui/icons-material/Close';

import { CoreModal, CoreModalProps } from '../../../_core/components/_ui/core-modal';

export type AddCustomFieldFormProps = CoreModalProps & {
	resolver: any;
	formError?: any;
	submitAction(customFields: CustomFieldInfoDto | Partial<CustomFieldInfoDto>): Promise<void>;
	closeModal(): void;
	defaultValues?: any;
	formTitle?: string;
	isEditMode?: boolean;
	isDefault?: boolean;
};

export const AddCustomFieldForm: React.FC<AddCustomFieldFormProps> = ({
	closeModal,
	submitAction,
	formError,
	defaultValues,
	resolver,
	formTitle,
	isEditMode = false,
	isDefault = false,
	...modalProps
}) => {
	const [t] = useTranslation();

	const {
		register,
		handleSubmit,
		getValues,
		trigger,
		setValue,
		watch,
		formState: { errors, isDirty, isLoading, isSubmitted, isValid },
	} = useForm<CustomFieldInfoDto>({
		defaultValues,
		resolver,
		mode: 'all',
	});

	const disableSubmit = !isValid && (isDirty || isSubmitted);

	const fieldType = watch('dataType');
	const optionsValue = watch('options');

	const handleAddNewOptionField = () => {
		const newOption = { _id: crypto.randomUUID(), text: '' };
		setValue('options', [...(getValues('options') || []), newOption]);
	};

	const handleOptionChange = (index: number, newText: string) => {
		const options = getValues('options') || [];
		const updatedOptions = options.map((option, idx) => (idx === index ? { ...option, text: newText } : option));
		setValue('options', updatedOptions);
	};

	const handleDeleteOption = (_id: string) => {
		const options = getValues('options') || [];
		const updatedOptions = options.filter((option) => option._id !== _id);
		setValue('options', updatedOptions);
	};

	const handleFormSubmit = useCallback(
		(values: CustomFieldInfoDto | Partial<CustomFieldInfoDto>) => {
			const newValues = { ...values };

			if (newValues.options) {
				newValues.options = newValues.options.map((option) => ({
					_id: isEditMode && option._id ? option._id : undefined,
					text: option.text,
				}));
			}

			submitAction(newValues);
		},
		[submitAction]
	);

	return (
		<CoreModal onClose={closeModal} {...modalProps} bodySx={{ pt: 0, m: 2 }} paperSx={{ width: '100%' }}>
			<Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
				{formTitle}
			</Typography>
			<Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', mb: '40px' }}>
					<CoreTextField
						label={t('custom-fields.name')}
						{...register('name')}
						requiredMark
						fullWidth
						error={!!errors['name']}
						helperText={errors['name'] ? (errors['name'] as FieldError).message : undefined}
					/>
					{!isDefault && (
						<CoreSelect
							label={'Engagement type'}
							{...register('engagementType')}
							requiredMark
							error={!!errors['engagementType']}
							helperText={errors['engagementType'] ? (errors['engagementType'] as FieldError).message : undefined}
							value={watch('engagementType')}
							fullWidth
						>
							{ServiceTypeStatusArray?.map((el: any) => (
								<MenuItem key={el} value={el}>
									{el}
								</MenuItem>
							))}
						</CoreSelect>
					)}

					<CoreSelect
						label={t('providers.verification-type')}
						{...register('isRequired')}
						requiredMark
						error={!!errors['isRequired']}
						helperText={errors['isRequired'] ? (errors['isRequired'] as FieldError).message : undefined}
						value={watch('isRequired')}
						fullWidth
					>
						<MenuItem value="true">{t('custom-fields.isRequired')}</MenuItem>
						<MenuItem value="false">{t('custom-fields.optional')}</MenuItem>
					</CoreSelect>
					<CoreSelect
						label="Data Type"
						{...register('dataType')}
						error={!!errors['dataType']}
						helperText={errors['dataType'] ? (errors['dataType'] as FieldError).message : undefined}
						requiredMark
						onChange={(e) => {
							setValue('dataType', e.target.value);
							trigger('dataType');

							if (e.target.value !== 'Options') {
								setValue('options', []);
							} else {
								setValue('options', optionsValue);
							}
						}}
						fullWidth
						value={watch('dataType') || ''}
					>
						{CustomFieldDataTypeArray.map((fieldType) => (
							<MenuItem key={fieldType} value={fieldType}>
								{fieldType}
							</MenuItem>
						))}
					</CoreSelect>

					{(fieldType === 'Options' || fieldType === 'MultiSelect') && (
						<Box sx={{ mt: 2 }}>
							{watch('options')?.map((option, index) => (
								<Box key={option._id} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
									<CoreTextField
										label={`Option ${index + 1}`}
										{...register(`options.${index}.text`)}
										error={!!errors?.options?.[index]?.text}
										helperText={errors?.options?.[index]?.text?.message || ''}
										defaultValue={option.text}
										onChange={(e) => handleOptionChange(index, e.target.value)}
										fullWidth
									/>
									<IconButton edge="end" onClick={() => option._id && handleDeleteOption(option._id)}>
										<CloseIcon />
									</IconButton>
								</Box>
							))}
							<Button variant="text" onClick={handleAddNewOptionField}>
								+ Add Option
							</Button>
						</Box>
					)}
					<Grid container spacing={2} justifyContent="flex-end">
						<Grid item>
							<CoreButton variant="secondary" onClick={closeModal}>
								{t('providers.close')}
							</CoreButton>
						</Grid>
						<Grid item>
							<CoreButton type="submit" loading={isLoading} disabled={disableSubmit}>
								{t('custom-fields.save')}
							</CoreButton>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</CoreModal>
	);
};
