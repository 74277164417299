import { useMuiModal } from '../../../_core/hooks';
import { FamilyFormModal, FamilyFormModalProps } from '../../components/modal-form';
import { CreateFamilyDto, UpdateFamilyDto, FamilyDto } from '@families-link/shared';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocalStorageDistrictId } from '../../../_core/utils';

export type useOpenModalFormHookProps = {
	featchAction(values: FormData): Promise<void>;
	handlePagination?(): void;
	isCreate: boolean;
	defaultValues?: any;
	submitButtonName?: string;
	toastText?: string;
};

export function useOpenModalForm(props: useOpenModalFormHookProps) {
	const initialDefaultValues = {
		fullName: '',
		email: '',
		phoneNumber: '',
		location: '',
		preferedLanguage: '',
		districtId: getLocalStorageDistrictId(),
		imageUrl: null,
	};

	const {
		featchAction,
		handlePagination,
		isCreate = true,
		defaultValues = initialDefaultValues,
		submitButtonName,
		toastText,
	} = props;

	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const showToast = (message: string, variant: 'error' | 'default' | 'success' | 'warning' | 'info' | undefined) => {
		enqueueSnackbar(message, {
			variant,
		});
	};

	const { openModal, closeModal, updateCurrentModal } = useMuiModal<FamilyFormModalProps>(FamilyFormModal);

	const handleSubmit = async (values: FormData): Promise<void> => {
		try {
			await featchAction(values);
			showToast(toastText || 'Successfully performed', 'success');
			if (handlePagination) {
				handlePagination();
			}

			closeModal();
		} catch (error) {
			updateCurrentModal({ formError: error });
		}
	};

	const resolver = isCreate ? classValidatorResolver(CreateFamilyDto) : classValidatorResolver(UpdateFamilyDto);
	const handleOpenModal = useCallback(
		(family?: FamilyDto) => {
			openModal({
				formTitle: isCreate ? t('families.forms.create-family') : t('families.forms.edit-family'),
				resolver,
				defaultValues: family ? family : defaultValues,
				submitAction: handleSubmit,
				closeModal,
				submitButtonName,
			});
		},
		[openModal, handleSubmit]
	);

	return handleOpenModal;
}
