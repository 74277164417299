import { styled } from '@mui/material/styles';
import { ButtonBase, ButtonBaseProps, Theme } from '@mui/material';
import { StyledComponent } from '@emotion/styled';

export type CoreButtonVariant = 'primary' | 'secondary' | 'danger' | 'info' | 'base';

export interface CoreButtonBaseProps extends ButtonBaseProps {
	variant?: CoreButtonVariant;
}

const getTextColor = (variant: CoreButtonVariant, theme: Theme) => {
	switch (variant) {
		case 'danger':
			return theme.colors.red;
		case 'primary':
			return theme.colors.orangeDeep;
		case 'secondary':
			return theme.colors.darkGray;
		case 'info':
			return theme.colors.blue;
		case 'base':
			return theme.colors.lightBlue;
	}
};

const getFocusColor = (variant: CoreButtonVariant, theme: Theme) => {
	switch (variant) {
		case 'danger':
			return theme.colors.redPressed;
		case 'primary':
			return '#E05920';
		case 'secondary':
			return theme.colors.black;
		case 'info':
			return theme.colors.bluePressed;
		case 'base':
			return theme.colors.white;
	}
};

export const CoreButtonBase: StyledComponent<CoreButtonBaseProps> = styled(ButtonBase)<CoreButtonBaseProps>(
	({ theme, variant = 'primary' }) => {
		const { typography, colors, transitions } = theme;

		const textColor = getTextColor(variant, theme);
		const focusColor = getFocusColor(variant, theme);

		return {
			...typography.button,
			textTransform: 'none',
			lineHeight: '24px',
			color: textColor,
			border: `1px solid ${textColor}`,
			borderRadius: '100px',
			padding: '11px 24px',
			transition: transitions.create(['background-color', 'color', 'border-color'], {
				duration: transitions.duration.short,
			}),
			'&:hover, &.Mui-focusVisible, &.core-button-loading': {
				color: colors.white,
				backgroundColor: variant === 'base' ? colors.grayLight : textColor,
				border: variant === 'base' ? `2px solid ${textColor}` : `1px solid ${textColor}`,
			},
			'&:active': {
				color: colors.white,
				backgroundColor: variant === 'base' ? colors.grayLight : textColor,
				borderColor: focusColor,
				border: variant === 'base' ? `2px solid ${textColor}` : `1px solid ${textColor}`,
			},
			'&.Mui-focusVisible': {
				boxShadow: `inset 0 0 0 1px ${focusColor}`,
				borderColor: focusColor,
			},
			'&:disabled:not(.core-button-loading)': {
				color: colors.blueTransparent,
				borderColor: colors.blueTransparent,
			},
		};
	}
);
