import { FamilyDto, UserRolesEnum } from '@families-link/shared';
import { FC, useCallback, useMemo } from 'react';
import { CoreTableActionsCell, CellAction } from '../../../_core/components/_ui/core-table';
import { FamiliesTableHandlers } from './families-table.component';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useCurrentAccessTokenSelector } from '../../../auth';
export interface SessionsTableActionsProps extends FamiliesTableHandlers {
	family: FamilyDto;
}

export const FamiliesTableActions: FC<SessionsTableActionsProps> = (props) => {
	const { family, onDelete, onEdit, onView } = props;
	const currentToken = useCurrentAccessTokenSelector();
	const isRequester = currentToken?.payload.sur === UserRolesEnum.Requester;

	const handleDeleteFamily = useCallback(() => {
		if (!family) {
			return;
		}

		onDelete?.(family);
	}, [onDelete, family]);

	const handleEditFamily = useCallback(() => {
		if (!family) {
			return;
		}

		onEdit?.(family);
	}, [onEdit, family]);

	const handleViewFamily = useCallback(() => {
		if (!family) {
			return;
		}

		onView?.(family);
	}, [onView, family]);

	const actions = useMemo<CellAction[]>(
		() => [
			{
				id: 'view',
				type: 'button',
				props: {
					children: <RemoveRedEyeOutlinedIcon color="primary" />,
					onClick: handleViewFamily,
				},
			},
			{
				id: 'more',
				type: 'menu',
				props: {
					options: [
						{
							id: 'edit',
							label: 'Edit',
							icon: <DriveFileRenameOutlineOutlinedIcon color="primary" />,
							onClick: handleEditFamily,
							disabled: isRequester,
						},
						{
							id: 'delete',
							label: 'Delete',
							icon: <DeleteOutlineOutlinedIcon color="primary" />,
							onClick: handleDeleteFamily,
							disabled: isRequester,
						},
					],
				},
			},
		],
		[handleViewFamily, handleEditFamily, handleDeleteFamily]
	);

	return <CoreTableActionsCell actions={actions} />;
};
