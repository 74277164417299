import React, { FC, useCallback, useEffect, useState } from 'react';
import { FieldError, FieldValues, ResolverOptions, useForm } from 'react-hook-form';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { CoreModal, CoreModalProps } from '../../../_core/components/_ui/core-modal';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { CreateFamilyDto, LanguageEnum, UpdateFamilyDto } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import {
	containerButtonStyles,
	buttonStyles,
	leftButtonStyles,
	inputBoxStyles,
	modalBoxStyles,
	errorTextStyles,
	containerFormStyles,
} from './modal-form.styles';
import { CoreAutoComplete } from '../../../_core/components/_ui/core-autocomplete';
import { getObjectLanguage } from '../../../_core/components/languages-component';
import Flag from 'react-flagkit';
import UploadPhoto from '../../../_core/components/upload-photo/upload-photo.component';
import { PhoneInput } from '../../../_core/components/_ui/phone-input';
import { InternationalFlag } from '../../../_core/constants/icons.constants';

export type FamilyFormModalProps = CoreModalProps & {
	formTitle: string;
	resolver: <TFieldValues extends FieldValues, TContext>(
		values: TFieldValues,
		context: TContext | undefined,
		options: ResolverOptions<TFieldValues>
	) => any;
	submitAction(values: FormData): Promise<void>;
	closeModal(): void;
	defaultValues?: any;
	formError?: any;
	submitButtonName?: string;
};

export const FamilyFormModal: FC<FamilyFormModalProps> = (props) => {
	const [t] = useTranslation();
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [isPhotoDeleted, setIsPhotoDeleted] = useState(false);
	const { formTitle, resolver, submitAction, formError, closeModal, defaultValues, submitButtonName, ...modalProps } =
		props;

	const {
		register,
		handleSubmit,
		getValues,
		setValue,
		setError,
		control,
		formState: { errors, isDirty, isLoading, isSubmitted, isValid },
	} = useForm({
		defaultValues,
		resolver,
		mode: 'all',
	});

	useEffect(() => {
		if (errors['preferedLanguage']) {
			setError('preferedLanguage', {
				type: 'manual',
				message: 'preferred language is required',
			});
		}
	}, [setError, errors['preferedLanguage']]);

	const disableSubmit = !isValid && (isDirty || isSubmitted);

	const handleFormSubmit = useCallback(
		async (values: CreateFamilyDto | UpdateFamilyDto) => {
			try {
				const formData = new FormData();

				Object.keys(values).forEach((key) => {
					formData.append(key, (values as any)[key]);
				});

				if (selectedFile) {
					formData.append('file', selectedFile);
				}

				if (selectedFile === null && !!isPhotoDeleted === true) {
					formData.delete('imageUrl');
					formData.append('imageUrl', '');
				}

				submitAction(formData);
			} catch (error) {
				console.error(error);
			}
		},
		[submitAction, selectedFile, isPhotoDeleted]
	);

	const handleFileChange = (file: File | null) => {
		if (file) {
			setSelectedFile(file);
		} else {
			setSelectedFile(null);
			setIsPhotoDeleted(true);
		}
	};

	return (
		<CoreModal {...modalProps} bodySx={{ pt: 0 }} paperSx={{ width: '100%' }}>
			<Box sx={modalBoxStyles}>
				<Typography variant="h5" sx={{ fontWeight: 'bold' }}>
					{formTitle}
				</Typography>
				<Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
					<Box sx={containerFormStyles}>
						<Box sx={{ width: '50%', padding: '16px', maxHeight: 364 }}>
							<UploadPhoto onFileChange={handleFileChange} editable file={defaultValues.imageUrl ?? null} />
						</Box>
						<Box sx={inputBoxStyles}>
							<CoreTextField
								label={t('families.forms.labels.full-name')}
								requiredMark
								fullWidth
								placeholder={t('families.forms.plaсeholders.full-name') ?? ''}
								{...register('fullName')}
								error={!!errors['fullName']}
								helperText={errors['fullName'] ? (errors['fullName'] as FieldError).message : undefined}
								controlSx={inputBoxStyles}
							/>
							<CoreTextField
								label={t('families.forms.labels.email')}
								requiredMark
								fullWidth
								placeholder={t('families.forms.plaсeholders.email') ?? ''}
								{...register('email')}
								error={!!errors['email']}
								helperText={errors['email'] ? (errors['email'] as FieldError).message : undefined}
								controlSx={inputBoxStyles}
							/>

							<PhoneInput
								fullWidth
								id="phone-number"
								controlSx={inputBoxStyles}
								requiredMark
								label={t('sign-up.registration-form.phone-number')}
								control={control}
								name="phoneNumber"
								error={!!errors['phoneNumber']}
								helperText={errors['phoneNumber'] ? (errors['phoneNumber'] as FieldError).message : undefined}
							/>

							<CoreTextField
								label={t('families.forms.labels.location')}
								requiredMark
								fullWidth
								placeholder={t('families.forms.plaсeholders.location') ?? ''}
								{...register('location')}
								error={!!errors['location']}
								helperText={errors['location'] ? (errors['location'] as FieldError).message : undefined}
								controlSx={inputBoxStyles}
							/>
							<CoreAutoComplete
								label={t('families.forms.labels.prefered-language')}
								requiredMark
								fullWidth
								{...register('preferedLanguage')}
								control={control}
								error={!!errors['preferedLanguage']}
								helperText={errors['preferedLanguage'] ? (errors['preferedLanguage'] as FieldError).message : undefined}
								options={Object.values(LanguageEnum)}
								getOptionLabel={(option) => {
									const language = getObjectLanguage(option);
									return language ? language.name : '';
								}}
								selectOnFocus={true}
								isOptionEqualToValue={(option, value) => option === value}
								renderInput={(params) => (
									<TextField
										{...params}
										placeholder={t('families.forms.plaсeholders.prefered-language') ?? ''}
										InputProps={{
											...params.InputProps,
											startAdornment: getValues('preferedLanguage') ? (
												getObjectLanguage(getValues('preferedLanguage'))?.code ? (
													<Flag country={getObjectLanguage(getValues('preferedLanguage'))?.code} />
												) : (
													<InternationalFlag style={{ width: '24px', height: '24px' }} />
												)
											) : null,
										}}
									/>
								)}
								renderOption={(props, option) => {
									const language = getObjectLanguage(option);
									return (
										<MenuItem {...props} key={language?.value} value={language?.value}>
											{language?.label}
										</MenuItem>
									);
								}}
								controlSx={inputBoxStyles}
							/>
						</Box>
					</Box>
					{formError && (
						<Typography sx={errorTextStyles}>
							{formError?.data?.message ? formError.data.message : t('errors.invalid-credentials-error')}
						</Typography>
					)}
					<Box sx={containerButtonStyles}>
						<CoreButton onClick={closeModal} variant="secondary" sx={leftButtonStyles}>
							{t('families.forms.buttons.close-form')}
						</CoreButton>
						<CoreButton type="submit" disabled={disableSubmit} loading={isLoading} sx={buttonStyles}>
							{submitButtonName || t('families.forms.buttons.save')}
						</CoreButton>
					</Box>
				</Box>
			</Box>
		</CoreModal>
	);
};
