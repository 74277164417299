import { useMemo } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { format, parseISO, parseJSON } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
	DefaultFieldTypeStatusEnum,
	EmailStatusColorEnum,
	EmailStatusEnum,
	EngagementDto,
	EngagementStatusColorEnum,
	EngagementStatusEnum,
	PermissionDto,
	SurveyStatusColorEnum,
	SurveyStatusEnum,
	WorkStatusEnum,
} from '@families-link/shared';
import { EngagementsTableHandlers } from '../../components/engagements-table/engagements-table.component';
import { EngagementsTableActions } from '../../components/engagements-table/engagements-table-actions.component';
import { getObjectLanguage } from '../../../_core/components/languages-component';
import { Verified, InHouse, Outsource, InternationalFlag } from '../../../_core/constants/icons.constants';
import { Avatar, Box, Typography } from '@mui/material';
import Flag from 'react-flagkit';
import { DistrictCell } from '../../../family/components/district-cell/district-cell.component';
const helper = createColumnHelper<EngagementDto & { createdAt: string; updatedAt: string }>();

export function useEngagementsTableColumns(
	handlers: EngagementsTableHandlers,
	permission?: PermissionDto
): ColumnDef<EngagementDto, string>[] {
	const { onDelete, onEdit, onView, onSendEmail, onSendSMS, onArchive, onUnarchive } = handlers;

	const [t] = useTranslation();

	const result = useMemo(
		() => [
			...(permission?.read
				? [
						helper.accessor('districtId', {
							header: 'District',
							meta: {
								flex: 1,
							},
							cell: ({ getValue }) => {
								const districtId = getValue();
								return <DistrictCell districtId={districtId} />;
							},
						}) as ColumnDef<EngagementDto, string>,
				  ]
				: []),
			helper.accessor('provider', {
				header: t('engagements.columns.providers'),
				meta: {
					flex: 2,
				},
				cell: ({ getValue, row }) => {
					const imageUrl = getValue()?.imageUrl;
					const fullName = getValue()?.fullName;
					const language = getValue()?.targetLanguage;
					const fullObjLang = getObjectLanguage(language);
					const workStatus = getValue()?.workStatus;
					const verificationType = getValue()?.verificationType;
					let workStatusIcon;
					switch (workStatus) {
						case WorkStatusEnum.InHouse:
							workStatusIcon = <InHouse style={{ marginLeft: '8px' }} />;
							break;
						case WorkStatusEnum.Outsource:
							workStatusIcon = <Outsource style={{ marginLeft: '8px' }} />;
							break;
						default:
							return null;
					}

					return (
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							{fullObjLang?.code ? (
								<Flag country={fullObjLang?.code} style={{ marginRight: '8px' }} />
							) : (
								<InternationalFlag style={{ width: '24px', height: '24px', marginRight: '8px' }} />
							)}
							<Box position="relative" sx={{ height: 28 }}>
								<Avatar
									src={imageUrl}
									alt={fullName}
									style={{ width: 24, height: 24, marginRight: 14, borderRadius: '6px' }}
								/>
								{verificationType ? (
									<Verified style={{ position: 'absolute', bottom: 0, right: 10, height: 12, width: 12 }} />
								) : null}
							</Box>
							<Typography sx={{ fontWeight: 'bold' }}>{fullName}</Typography>
							{workStatusIcon}
						</Box>
					);
				},
			}) as ColumnDef<EngagementDto, string>,
			helper.accessor('customId', {
				header: t('engagements.columns.id'),
				meta: {
					flex: 0.6,
				},
			}) as ColumnDef<EngagementDto, string>,
			helper.accessor((row) => row.customFields?.find((el) => el.name === 'Title')?.name, {
				header: t('engagements.columns.title'),
				meta: {
					flex: 0.9,
				},
				cell: ({ row }) => {
					const fieldTitleId = row.original.customFields?.find((el) => el.name === 'Title')?._id;
					const fieldTitleValue = row.original.customFieldsValues?.find(
						(el) => el.fieldId.toString() === fieldTitleId
					)?.value;
					return <Typography>{fieldTitleValue}</Typography>;
				},
			}) as ColumnDef<EngagementDto, string>,

			...(!permission?.isDetailsPage
				? [
						helper.accessor((row) => row.customFields?.find((el) => el.name === 'Type')?.name || '', {
							header: t('engagements.columns.type'),
							meta: {
								flex: 0.9,
							},
							cell: ({ row }) => {
								const fieldTypeId = row.original.customFields?.find((el) => el.name === 'Type')?._id;
								const fieldTypeValue = row.original.customFieldsValues?.find(
									(el) => el.fieldId.toString() === fieldTypeId
								)?.value;
								return <Typography>{fieldTypeValue}</Typography>;
							},
						}) as ColumnDef<EngagementDto, string>,
						helper.accessor((row) => row.customFields?.find((el) => el.name === 'School')?.name || '', {
							header: t('engagements.columns.school'),
							meta: {
								flex: 0.7,
							},
							cell: ({ row }) => {
								const fieldSchoolId = row.original.customFields?.find((el) => el.name === 'School')?._id;
								const fieldSchoolValue = row.original.customFieldsValues?.find(
									(el) => el.fieldId.toString() === fieldSchoolId
								)?.valuePlaceholder;
								return <Typography>{fieldSchoolValue}</Typography>;
							},
						}) as ColumnDef<EngagementDto, string>,
						helper.accessor((row) => row.customFields?.find((el) => el.name === 'Program')?.name || '', {
							header: t('engagements.columns.program'),
							meta: {
								flex: 0.7,
							},
							cell: ({ row }) => {
								const fieldProgramId = row.original.customFields?.find((el) => el.name === 'Program')?._id;
								const fieldProgramValue = row.original.customFieldsValues?.find(
									(el) => el.fieldId.toString() === fieldProgramId
								)?.valuePlaceholder;
								return <Typography>{fieldProgramValue}</Typography>;
							},
						}) as ColumnDef<EngagementDto, string>,
				  ]
				: []),
			helper.accessor((row) => row.customFields?.find((el) => el.name === 'Duration')?.name || '', {
				header: t('engagements.columns.date'),
				meta: {
					flex: 1.4,
				},
				cell: ({ row }) => {
					const fieldDurationId = row.original.customFields?.find((el) => el.name === 'Duration')?._id;
					const fieldDurationValue = row.original.customFieldsValues?.find(
						(el) => el.fieldId.toString() === fieldDurationId
					)?.value;
					const fieldTypeId = row.original.customFields?.find((el) => el.name === 'Type')?._id;
					const fieldTypeValue = row.original.customFieldsValues?.find(
						(el) => el.fieldId.toString() === fieldTypeId
					)?.value;
					return fieldTypeValue === DefaultFieldTypeStatusEnum.Appointment
						? `${format(new Date(fieldDurationValue.startDate), 'MM.dd.yyyy')}`
						: `${format(new Date(fieldDurationValue.startDate), 'MM.dd.yyyy')}-${format(
								new Date(fieldDurationValue.endDate),
								'MM.dd.yyyy'
						  )}`;
				},
			}) as ColumnDef<EngagementDto, string>,
			helper.accessor('status', {
				header: t('engagements.columns.status'),
				meta: {
					flex: 0.6,
				},
				cell: ({ getValue }) => {
					const engagementStatus = getValue() as EngagementStatusEnum;
					return (
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Box
								sx={{
									height: 10,
									width: 10,
									marginRight: 1,
									borderRadius: '50%',
									backgroundColor: EngagementStatusColorEnum[engagementStatus],
									flexShrink: 0,
								}}
							></Box>
							<Typography variant="body2">{engagementStatus}</Typography>
						</Box>
					);
				},
			}) as ColumnDef<EngagementDto, string>,
			helper.accessor((row) => row.customFields?.find((el) => el.name === 'Email status')?.name || '', {
				header: t('engagements.columns.email-status'),
				meta: {
					flex: 0.7,
				},
				cell: ({ row }) => {
					const fieldEmailStatusId = row.original.customFields?.find((el) => el.name === 'Email status')?._id;
					const fieldEmailStatusValue = row.original.customFieldsValues?.find(
						(el) => el.fieldId.toString() === fieldEmailStatusId
					)?.value as EmailStatusEnum;
					return (
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Box
								sx={{
									height: 10,
									width: 10,
									marginRight: 1,
									borderRadius: '50%',
									backgroundColor: EmailStatusColorEnum[fieldEmailStatusValue],
									flexShrink: 0,
								}}
							></Box>
							<Typography variant="body2">{fieldEmailStatusValue}</Typography>
						</Box>
					);
				},
			}) as ColumnDef<EngagementDto, string>,
			helper.accessor((row) => row.customFields?.find((el) => el.name === 'Survey status')?.name || '', {
				header: t('engagements.columns.survey-status'),
				meta: {
					flex: 0.7,
				},
				cell: ({ row }) => {
					const fieldSurveyStatusId = row.original.customFields?.find((el) => el.name === 'Survey status')?._id;
					const fieldSurveyValue = row.original.customFieldsValues?.find(
						(el) => el.fieldId.toString() === fieldSurveyStatusId
					)?.value as SurveyStatusEnum;
					return (
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Box
								sx={{
									height: 10,
									width: 10,
									marginRight: 1,
									borderRadius: '50%',
									backgroundColor: SurveyStatusColorEnum[fieldSurveyValue],
									flexShrink: 0,
								}}
							></Box>
							<Typography variant="body2">{fieldSurveyValue}</Typography>
						</Box>
					);
				},
			}) as ColumnDef<EngagementDto, string>,

			helper.display({
				id: 'actions',
				meta: {
					flex: 1,
				},
				cell: (props) => (
					<EngagementsTableActions
						engagement={props.row.original}
						onDelete={onDelete}
						onEdit={onEdit}
						onView={onView}
						onSendEmail={onSendEmail}
						onSendSMS={onSendSMS}
						onArchive={onArchive}
						onUnarchive={onUnarchive}
					/>
				),
			}) as ColumnDef<EngagementDto>,
		],
		[t, onDelete, onEdit, onArchive]
	);

	return result;
}
