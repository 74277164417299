import React, { FC, useEffect, useState } from 'react';
import { Avatar, Box, Checkbox, MenuItem, TextField, Typography } from '@mui/material';
import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CoreModal, CoreModalProps } from '../../../_core/components/_ui/core-modal';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import {
	CustomFieldDto,
	DefaultFieldTypeStatusArray,
	DefaultFieldTypeStatusEnum,
	EngagementStatusArray,
	FamilyDto,
	FieldDataTypeEnum,
	FieldOptionInterface,
	FieldValueInterface,
	ProviderDto,
	ServiceTypeStatusEnum,
} from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import {
	containerButtonStyles,
	buttonStyles,
	leftButtonStyles,
	inputBoxStyles,
	modalBoxStyles,
	errorTextStyles,
	containerFormStyles,
} from './modal-form.styles';
import { CoreAutoComplete } from '../../../_core/components/_ui/core-autocomplete';
import { CoreSelect } from '../../../_core/components/_ui/core-select';
import { CoreDateRangePicker } from '../../../_core/components/_ui/core-date-range-picker';
import dayjs from 'dayjs';
import { CoreDatePicker } from '../../../_core/components/_ui/core-date-picker';
import { CoreTimeField } from '../../../_core/components/_ui/core-time-field';
import { CoreDurationDropdown } from '../../../_core/components/_ui/core-duration-dropdown';
import { DynamicFileField } from '../dynamic-file-field/dynamic-file-field.compnent';

export type EngagementFormModalProps = CoreModalProps & {
	formTitle: string;
	submitAction(values: any): Promise<void>;
	closeModal(): void;
	defaultValues?: any;
	formError?: any;
	submitButtonName?: string;
	fieldsListFromDistrict?: CustomFieldDto[];
	providersList?: ProviderDto[];
	familiesList?: FamilyDto[];
};

export const EngagementFormModal: FC<EngagementFormModalProps> = (props) => {
	const [t] = useTranslation();

	const {
		formTitle,
		submitAction,
		formError,
		closeModal,
		defaultValues,
		fieldsListFromDistrict,
		providersList,
		familiesList,
		submitButtonName,
		...modalProps
	} = props;

	const methods = useForm({
		defaultValues,
		mode: 'all',
	});

	const {
		register,
		handleSubmit,
		reset,
		control,
		watch,
		formState: { errors, isDirty, isLoading, isSubmitted, isValid },
	} = methods;

	const fieldsList: CustomFieldDto[] =
		fieldsListFromDistrict?.length === 0 ? defaultValues.customFields : fieldsListFromDistrict;

	const fileFormFields = fieldsList
		?.filter((el: CustomFieldDto) => el.dataType === FieldDataTypeEnum.File)
		.map((el) => el._id);

	const disableSubmit = !isValid && (isDirty || isSubmitted);

	const defaultFormFields = {
		title: fieldsList?.find((el: CustomFieldDto) => el.name === 'Title'),
		type: fieldsList?.find((el: CustomFieldDto) => el.name === 'Type'),
		school: fieldsList?.find((el: CustomFieldDto) => el.name === 'School'),
		program: fieldsList?.find((el: CustomFieldDto) => el.name === 'Program'),
		duration: fieldsList?.find((el: CustomFieldDto) => el.name === 'Duration'),
		description: fieldsList?.find((el: CustomFieldDto) => el.name === 'Description'),
		service: fieldsList?.find((el: CustomFieldDto) => el.name === 'Service'),
	};

	const typeInForm = watch(`field_${defaultFormFields.type?._id}`);

	const [filteredProviders, setFilteredProviders] = useState(providersList);
	const [filteredFamilies, setFilteredFamilies] = useState(familiesList);

	const appointmentFields = fieldsList.filter(
		(field) =>
			!field.defaultFieldId &&
			(field.engagementType === DefaultFieldTypeStatusEnum.Appointment ||
				field.engagementType === ServiceTypeStatusEnum.Both)
	);

	const projectFields = fieldsList.filter(
		(field) =>
			!field.defaultFieldId &&
			(field.engagementType === ServiceTypeStatusEnum.Both ||
				field.engagementType === DefaultFieldTypeStatusEnum.Project)
	);

	const providerInForm = watch('provider');
	const familyInForm = watch('family');

	useEffect(() => {
		if (providerInForm) {
			setFilteredFamilies(familiesList?.filter((family) => family.preferedLanguage === providerInForm.targetLanguage));
		} else {
			setFilteredFamilies(familiesList);
		}
	}, [providerInForm]);

	useEffect(() => {
		if (familyInForm) {
			setFilteredProviders(
				providersList?.filter((provider) => provider.targetLanguage === familyInForm.preferedLanguage)
			);
		} else {
			setFilteredProviders(providersList);
		}
	}, [familyInForm]);

	useEffect(() => {
		if (typeInForm === DefaultFieldTypeStatusEnum.Project) {
			setFilteredProviders(
				providersList?.filter((provider) => provider.serviceType === 'Project' || provider.serviceType === 'Both')
			);
			reset((formValues: any) => ({
				...formValues,
				...appointmentFields.reduce((res: any, el) => {
					res[`field_${el._id}`] = defaultValues[`field_${el._id}`];
					return res;
				}, {}),
			}));
		} else if (typeInForm === DefaultFieldTypeStatusEnum.Appointment) {
			setFilteredProviders(
				providersList?.filter((provider) => provider.serviceType === 'Appointment' || provider.serviceType === 'Both')
			);
			reset((formValues: any) => ({
				...formValues,
				...projectFields.reduce((res: any, el) => {
					res[`field_${el._id}`] = defaultValues[`field_${el._id}`];
					return res;
				}, {}),
			}));
		}
	}, [typeInForm, reset, providersList]);

	const handleFormSubmit = async (values: any) => {
		const transformedValues = Object.keys(values).reduce(
			(submitObject: { [k: string]: any }, key) => {
				if (
					typeInForm === DefaultFieldTypeStatusEnum.Appointment &&
					(key === `day` || key === 'time' || key === 'appointmentDuration')
				) {
					const existField = submitObject['customFieldsValues'].find(
						(el: FieldValueInterface) => el.fieldId.toString() === defaultFormFields.duration?._id
					);
					const startDate = dayjs(values[`day`])
						.hour(values['time'].hour())
						.minute(values['time'].minute())
						.second(values['time'].second());
					const endDate = startDate.add(values['appointmentDuration'], 'millisecond');
					if (existField) {
						existField.value = {
							startDate: startDate.toDate(),
							endDate: endDate.toDate(),
						};
					} else {
						submitObject['customFieldsValues'].push({
							fieldId: defaultFormFields.duration?._id,
							value: { startDate: startDate.toDate(), endDate: endDate.toDate() },
						});
					}
				} else if (
					typeInForm === DefaultFieldTypeStatusEnum.Project &&
					key === `field_${defaultFormFields.duration?._id}`
				) {
					const existField = submitObject['customFieldsValues'].find(
						(el: FieldValueInterface) => el.fieldId.toString() === key.replace('field_', '')
					);
					if (existField) {
						existField.value = {
							startDate: dayjs(new Date(values[key][0])).hour(0).minute(0).second(0).toDate(),
							endDate: dayjs(new Date(values[key][1])).hour(23).minute(59).second(59).toDate(),
						};
					} else {
						submitObject['customFieldsValues'].push({
							fieldId: key.replace('field_', ''),
							value: {
								startDate: dayjs(new Date(values[key][0])).hour(0).minute(0).second(0).toDate(),
								endDate: dayjs(new Date(values[key][1])).hour(23).minute(59).second(59).toDate(),
							},
						});
					}
				} else if (key.startsWith('field_')) {
					if (key !== `field_${defaultFormFields.duration?._id}`) {
						const existField = submitObject['customFieldsValues'].find(
							(el: FieldValueInterface) => el.fieldId.toString() === key.replace('field_', '')
						);
						if (existField) {
							existField.value = values[key];
						} else {
							const fieldId = key.replace('field_', '');
							submitObject['customFieldsValues'].push({
								fieldId,
								value: values[key],
							});
						}
					}
				} else if (key === 'provider') {
					submitObject['providerId'] = values[key]._id;
				} else if (key === 'family') {
					submitObject['familyId'] = values[key]._id;
				} else {
					submitObject[key] = values[key];
				}
				return submitObject;
			},
			{ customFieldsValues: [] }
		);
		try {
			const formData = new FormData();
			const listOfFiles: any[] = [];
			Object.keys(transformedValues).forEach((key) => {
				if (key === 'customFieldsValues') {
					const newCustomFields = (transformedValues as any)[key].map((el: FieldValueInterface) => {
						if (fileFormFields?.includes(el.fieldId.toString()) && el.value && !el.value?.url) {
							listOfFiles.push({ _id: el.fieldId, file: el.value });
							return {
								fieldId: el.fieldId,
								value: el.value ? { name: el.value.name, type: el.value.type, url: undefined } : el.value,
							};
						} else {
							return { fieldId: el.fieldId, value: el.value };
						}
					});
					formData.append(key, JSON.stringify(newCustomFields));
				} else {
					formData.append(key, (transformedValues as any)[key]);
				}
			});
			listOfFiles.forEach((el) => {
				formData.append(`files_${el._id}`, el.file);
			});
			submitAction(formData);
		} catch (error) {
			console.error(error);
		}
	};

	const validateField = (value: any, field?: CustomFieldDto) => {
		if (field?.isRequired && !value) {
			return `${field?.name} is required`;
		}

		return true;
	};

	const getInput = (customField?: CustomFieldDto, label?: string, statusEnum?: any[]) => {
		const inputName = `field_${customField?._id}`;
		switch (customField?.dataType) {
			case FieldDataTypeEnum.SystemStatus:
				return (
					<Controller
						key={inputName}
						name={inputName}
						control={control}
						rules={{
							validate: (value) => validateField(value, customField),
						}}
						render={({ field }) => (
							<CoreSelect
								{...field}
								label={label || customField?.name || ''}
								requiredMark={customField?.isRequired}
								fullWidth
								placeholder={t('engagements.forms.plaсeholders.options') ?? ''}
								error={!!errors[inputName]}
								helperText={errors[inputName] ? (errors[inputName] as FieldError).message : undefined}
								controlSx={inputBoxStyles}
							>
								{statusEnum?.map((el: any) => (
									<MenuItem key={el} value={el}>
										{el}
									</MenuItem>
								))}
							</CoreSelect>
						)}
					/>
				);
			case FieldDataTypeEnum.Options:
				return (
					<Controller
						key={inputName}
						name={inputName}
						control={control}
						rules={{
							validate: (value) => validateField(value, customField),
						}}
						render={({ field }) => (
							<CoreSelect
								{...field}
								{...register(inputName)}
								label={label || customField?.name || ''}
								requiredMark={customField?.isRequired}
								fullWidth
								placeholder={t('engagements.forms.plaсeholders.options') ?? ''}
								error={!!errors[inputName]}
								helperText={errors[inputName] ? (errors[inputName] as FieldError).message : undefined}
								controlSx={inputBoxStyles}
							>
								{customField?.options?.map((el: any) => (
									<MenuItem key={el._id} value={el._id}>
										{el.text}
									</MenuItem>
								))}
							</CoreSelect>
						)}
					/>
				);
			case FieldDataTypeEnum.MultiSelect:
				return (
					<Controller
						key={inputName}
						name={inputName}
						control={control}
						rules={{
							validate: (value) => validateField(value, customField),
						}}
						render={({ field }) => (
							<CoreSelect
								{...field}
								label={customField?.name || ''}
								requiredMark={customField?.isRequired}
								placeholder={t('engagements.forms.plaсeholders.options') ?? ''}
								fullWidth
								SelectProps={{
									multiple: true,
									value: field.value || [],
									renderValue: (selected: any) => {
										if (!selected || selected.length === 0) {
											return <span style={{ color: '#999' }}>{t('engagements.forms.plaсeholders.options')}</span>;
										}

										return selected
											.map(
												(selectedId: string) =>
													customField?.options?.find((opt: FieldOptionInterface) => opt._id === selectedId)?.text
											)
											.join(', ');
									},
								}}
								error={!!errors[inputName]}
								helperText={errors[inputName] ? (errors[inputName] as any).message : undefined}
							>
								{customField?.options?.map((option: FieldOptionInterface) => (
									<MenuItem key={option._id} value={option._id}>
										<Checkbox checked={field.value ? field.value.includes(option._id) : false} />
										{option.text}
									</MenuItem>
								))}
							</CoreSelect>
						)}
					/>
				);
			case FieldDataTypeEnum.DateDuration:
				if (typeInForm === DefaultFieldTypeStatusEnum.Appointment) {
					return (
						<Box sx={containerFormStyles}>
							<Controller
								key={'day'}
								name={`day`}
								control={control}
								rules={{
									validate: (value) => {
										if (!value) {
											return `Day is required`;
										}
										return true;
									},
								}}
								render={({ field }) => (
									<CoreDatePicker
										{...field}
										label={'Day'}
										requiredMark={true}
										fullWidth
										error={!!errors['day']}
										helperText={errors['day'] ? (errors['day'] as FieldError).message : undefined}
										controlSx={inputBoxStyles}
									/>
								)}
							/>
							<Controller
								key={'time'}
								name={`time`}
								control={control}
								rules={{
									validate: (value) => {
										if (!value) {
											return `Time is required`;
										}
										return true;
									},
								}}
								render={({ field }) => (
									<CoreTimeField
										{...field}
										label={'Time'}
										requiredMark={true}
										fullWidth
										error={!!errors['time']}
										helperText={errors['time'] ? (errors['time'] as FieldError).message : undefined}
										controlSx={inputBoxStyles}
									/>
								)}
							/>
						</Box>
					);
				} else {
					return (
						<Controller
							key={inputName}
							name={inputName}
							control={control}
							rules={{
								validate: (value) => validateField(value, customField),
							}}
							render={({ field }) => (
								<CoreDateRangePicker
									{...field}
									label={label || customField?.name || ''}
									requiredMark={customField?.isRequired}
									fullWidth
									clean={!field.value}
									error={!!errors[inputName]}
									helperText={errors[inputName] ? (errors[inputName] as FieldError).message : undefined}
									controlSx={inputBoxStyles}
								/>
							)}
						/>
					);
				}

			case FieldDataTypeEnum.LongText:
				return (
					<CoreTextField
						key={inputName}
						{...register(inputName, {
							required: customField?.isRequired ? `${label || customField?.name || ''} is required` : false,
						})}
						label={label || customField?.name || ''}
						requiredMark={customField?.isRequired}
						fullWidth
						multiline
						placeholder={t('engagements.forms.plaсeholders.text') ?? ''}
						error={!!errors[inputName]}
						helperText={errors[inputName] ? (errors[inputName] as FieldError).message : undefined}
						controlSx={inputBoxStyles}
					/>
				);
			case FieldDataTypeEnum.File:
				return <DynamicFileField customField={customField} controlSx={inputBoxStyles} />;

			default:
				return (
					<CoreTextField
						key={inputName}
						{...register(inputName, {
							required: customField?.isRequired ? `${label || customField?.name || ''} is required` : false,
						})}
						label={label || customField?.name || ''}
						requiredMark={customField?.isRequired}
						fullWidth
						placeholder={t('engagements.forms.plaсeholders.text') ?? ''}
						error={!!errors[inputName]}
						helperText={errors[inputName] ? (errors[inputName] as FieldError).message : undefined}
						controlSx={inputBoxStyles}
					/>
				);
		}
	};

	return (
		<CoreModal {...modalProps} bodySx={{ pt: 0 }} paperSx={{ width: '100%' }}>
			<Box sx={modalBoxStyles}>
				<Typography variant="h5" sx={{ fontWeight: 'bold' }}>
					{formTitle}
				</Typography>
				<FormProvider {...methods}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
							<Box sx={inputBoxStyles}>
								<Box sx={containerFormStyles}>
									{defaultFormFields.title
										? getInput(defaultFormFields.title, t('engagements.forms.labels.title'))
										: null}
									{defaultFormFields.type
										? getInput(defaultFormFields.type, t('engagements.forms.labels.type'), DefaultFieldTypeStatusArray)
										: null}
								</Box>
								<Box sx={containerFormStyles}>
									{defaultFormFields.school
										? getInput(defaultFormFields.school, t('engagements.forms.labels.school'))
										: null}
									{defaultFormFields.program
										? getInput(defaultFormFields.program, t('engagements.forms.labels.program'))
										: null}
								</Box>
								{typeInForm === DefaultFieldTypeStatusEnum.Appointment
									? defaultFormFields.duration
										? getInput(defaultFormFields.duration, t('engagements.forms.labels.duration'))
										: null
									: null}
								<Box sx={containerFormStyles}>
									{typeInForm === DefaultFieldTypeStatusEnum.Appointment ? (
										<CoreDurationDropdown
											label={t('engagements.forms.labels.duration')}
											requiredMark
											fullWidth
											placeholder={t('engagements.forms.plaсeholders.options') ?? ''}
											{...register('appointmentDuration', {
												required: `${t('engagements.forms.labels.duration')} is required`,
											})}
											value={watch('appointmentDuration') || ''}
											error={!!errors['appointmentDuration']}
											helperText={
												errors['appointmentDuration']
													? (errors['appointmentDuration'] as FieldError).message
													: undefined
											}
											controlSx={inputBoxStyles}
										/>
									) : defaultFormFields.duration ? (
										getInput(defaultFormFields.duration, t('engagements.forms.labels.duration'))
									) : null}

									<CoreSelect
										label={t('engagements.forms.labels.status')}
										requiredMark
										fullWidth
										placeholder={t('engagements.forms.plaсeholders.options') ?? ''}
										{...register('status', { required: `${t('engagements.forms.labels.status')} is required` })}
										value={watch('status') || ''}
										error={!!errors['status']}
										helperText={errors['status'] ? (errors['status'] as FieldError).message : undefined}
										controlSx={inputBoxStyles}
									>
										{EngagementStatusArray.map((value) => (
											<MenuItem key={value} value={value}>
												{value}
											</MenuItem>
										))}
									</CoreSelect>
								</Box>
								<Box sx={containerFormStyles}>
									<CoreAutoComplete
										label={t('engagements.forms.labels.provider')}
										requiredMark
										fullWidth
										{...register('provider')}
										control={control}
										ruleIsRequired
										ruleName={t('engagements.forms.labels.provider')}
										error={!!errors['provider']}
										helperText={errors['provider'] ? (errors['provider'] as FieldError).message : undefined}
										options={filteredProviders || []}
										getOptionLabel={(option) => option.fullName || ''}
										selectOnFocus={true}
										isOptionEqualToValue={(option, value) => option._id === value._id}
										renderInput={(params) => (
											<TextField {...params} placeholder={t('engagements.forms.plaсeholders.options') ?? ''} />
										)}
										renderOption={(props, option) => (
											<MenuItem {...props} key={option._id} sx={{ display: 'flex', alignItems: 'center' }}>
												<Avatar
													src={option.imageUrl}
													alt={option.fullName}
													style={{ width: 24, height: 24, marginRight: 18, borderRadius: '6px' }}
												/>
												{option.fullName}
											</MenuItem>
										)}
										controlSx={inputBoxStyles}
									/>
									{defaultFormFields.service
										? getInput(defaultFormFields.service, t('engagements.forms.labels.service'))
										: null}
								</Box>
								<CoreAutoComplete
									label={t('engagements.forms.labels.family')}
									requiredMark
									fullWidth
									{...register('family')}
									control={control}
									ruleIsRequired
									ruleName={t('engagements.forms.labels.family')}
									error={!!errors['family']}
									helperText={errors['family'] ? (errors['family'] as FieldError).message : undefined}
									options={filteredFamilies || []}
									getOptionLabel={(option) => option.fullName || ''}
									selectOnFocus={true}
									isOptionEqualToValue={(option, value) => option._id === value._id}
									renderInput={(params) => (
										<TextField {...params} placeholder={t('engagements.forms.plaсeholders.options') ?? ''} />
									)}
									renderOption={(props, option) => (
										<MenuItem {...props} key={option._id} sx={{ display: 'flex', alignItems: 'center' }}>
											<Avatar
												src={option.imageUrl}
												alt={option.fullName}
												style={{ width: 24, height: 24, marginRight: 18, borderRadius: '6px' }}
											/>
											{option.fullName}
										</MenuItem>
									)}
									controlSx={inputBoxStyles}
								/>

								{defaultFormFields.description
									? getInput(defaultFormFields.description, t('engagements.forms.labels.description'))
									: null}

								{typeInForm === DefaultFieldTypeStatusEnum.Project
									? projectFields.map((el) => getInput(el))
									: appointmentFields.map((el) => getInput(el))}
							</Box>

							{formError && (
								<Typography sx={errorTextStyles}>
									{formError?.data?.message ? formError.data.message : t('errors.invalid-credentials-error')}
								</Typography>
							)}
							<Box sx={containerButtonStyles}>
								<CoreButton onClick={closeModal} variant="secondary" sx={leftButtonStyles}>
									{t('engagements.forms.buttons.close-form')}
								</CoreButton>
								<CoreButton type="submit" disabled={disableSubmit} loading={isLoading} sx={buttonStyles}>
									{submitButtonName || t('engagements.forms.buttons.save')}
								</CoreButton>
							</Box>
						</Box>
					</LocalizationProvider>
				</FormProvider>
			</Box>
		</CoreModal>
	);
};
