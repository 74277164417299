import React, { FC, forwardRef, Ref } from 'react';
import { CoreFormControl, CoreFormControlProps } from '../core-form-control';
import styled from 'styled-components';
import { DateRangePicker, DateRangePickerProps } from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

export interface CoreDateRangePickerProps extends CoreFormControlProps, DateRangePickerProps {
	small?: boolean;
	clean?: boolean;
	wrapperRef?: Ref<unknown>;
}

export const CoreStyledDateRangePicker: FC<CoreDateRangePickerProps> = styled(
	DateRangePicker
)<CoreDateRangePickerProps>(({ theme, small, clean }) => ({
	'.react-daterange-picker__wrapper': {
		height: '48px',
		border: `1px solid rgba(36, 57, 106, 0.16)`,
		borderRadius: '12px',
		fontSize: small ? '8px' : '12px',
		color: '#0A0A0A',
		justifyContent: 'space-evenly',
		'&:hover': {
			borderColor: '#0A0A0A',
		},
		'&.focused': {
			borderColor: '#2196f3',
		},
		'&.error': {
			borderColor: '#F8223C',
		},
		'&.disabled': {
			borderColor: 'rgba(36, 57, 106, 0.16)',
			color: '#9D9D9D',
			backgroundColor: '#F6F7F9',
			cursor: 'not-allowed',
		},
		'>div:first-child': {
			paddingLeft: '6px',
		},
	},

	'.react-daterange-picker__inputGroup__input': {
		// minWidth: '1em',
		height: '100%',
		position: 'relative',
		padding: '0 1px',
		border: 0,
		background: 'none',
		font: 'inherit',
		fontSize: '1.3em',
		boxSizing: 'content-box',
		appearance: 'textfield',
	},

	'.react-daterange-picker--open': {
		'&*': {
			backgroundColor: 'none',
		},
		borderColor: '#2196f3',
		'&.focused': {
			borderColor: 'none',
		},
	},

	'.react-daterange-picker__inputGroup': {
		width: '40%',
		display: 'flex',
		alignItems: 'center',
		flexGrow: 0,
		justifyContent: 'space-evenly',
	},

	'.react-daterange-picker__clear-button': {
		opacity: clean ? 0 : 100,
		pointerEvents: clean ? 'none' : 'auto',
	},

	'& .react-calendar': {
		border: 'none',
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
	},
}));

export const CoreDateRangePicker = forwardRef<unknown, CoreDateRangePickerProps>((props, ref) => {
	const {
		id,
		fullWidth,
		controlSx,
		label,
		helperText,
		error,
		disabled,
		focused,
		wrapperRef,
		requiredMark,
		...inputProps
	} = props;

	return (
		<CoreFormControl
			disabled={disabled}
			error={error}
			helperText={helperText}
			label={label}
			id={id}
			requiredMark={requiredMark}
			focused={focused}
			fullWidth={fullWidth}
			controlSx={controlSx}
		>
			<CoreStyledDateRangePicker
				locale="en"
				calendarProps={{ calendarType: 'gregory' }}
				dayPlaceholder="DD"
				monthPlaceholder="MM"
				yearPlaceholder="YYYY"
				{...inputProps}
			/>
		</CoreFormControl>
	);
});
