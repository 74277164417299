import { Box, Container, Paper } from '@mui/material';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FamilyDetails } from '../../../family/components/family-details/family-details.component';
import { ProvidersDetails } from '../../../providers/components/providers-details/providers-details.component';
import { useGetEngagementQuery } from '../../../../store/api/engagements.api';
import { getLocalStorageEngagementId } from '../../../_core/utils';
import { EngagementDetails } from '../../components/engagement-details/engagement-details.component';
import { useInitiateState } from '../../../_core/hooks';
import { useAppSelector } from '../../../../store';

export const EngagementDetailsPage: FC = () => {
	const currentDistrictId = useAppSelector((state) => state.district.currentDistrict);
	useInitiateState('engagement');

	const [t] = useTranslation();
	const engagementId = getLocalStorageEngagementId();
	const { data: currentEngagement, error, isLoading } = useGetEngagementQuery({ id: engagementId });

	return (
		<Container
			maxWidth={false}
			disableGutters
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				padding: 0,
				margin: 0,
				height: '100vh',
				overflow: 'auto',
				paddingBottom: '16px',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					height: '32%',
					pb: 1,
				}}
			>
				<Paper elevation={0} sx={{ width: '50%', mr: 1, pb: 2 }}>
					{currentEngagement && <ProvidersDetails providerId={currentEngagement.providerId} />}
				</Paper>
				<Paper elevation={0} sx={{ width: '50%', pb: 2 }}>
					{currentEngagement && <FamilyDetails familyId={currentEngagement.familyId} isEngagementPage={true} />}
				</Paper>
			</Box>
			<Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
				<Box sx={{ height: '100%', width: '58%', mr: 1 }}>
					<Paper
						elevation={0}
						sx={{
							width: '100%',
							height: '100%',
							pt: 2,
							pb: 2,
							pr: 3,
							pl: 3,
							borderBottomRightRadius: 0,
							borderBottomLeftRadius: 0,
							overflowY: 'auto',
						}}
					>
						<EngagementDetails />
					</Paper>
				</Box>
				<Box
					sx={{
						width: '42%',
					}}
				>
					<Paper
						elevation={0}
						sx={{
							width: '100%',
							height: '100%',
							borderBottomRightRadius: 0,
							borderBottomLeftRadius: 0,
							overflowY: 'auto',
						}}
					></Paper>
				</Box>
			</Box>
		</Container>
	);
};
