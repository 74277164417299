import { IsString, IsBoolean, IsOptional, IsArray, ValidateNested, IsEnum, IsNotEmpty, Length } from 'class-validator';

export class FieldDto {
	@IsOptional()
	@IsString()
	_id?: string;

	@IsNotEmpty()
	@Length(1, 100)
	name: string;

	@IsNotEmpty()
	dataType: string;

	@IsNotEmpty()
	isRequired: boolean;

	@IsOptional()
	@IsNotEmpty()
	value?: string;

	@IsOptional()
	@IsString()
	fileUrl?: string;

	@IsOptional()
	@IsEnum(['Default', 'Custom'])
	attributeType?: string;

	@IsOptional()
	@IsString()
	engagementType?: string;
}
