export const DistrictRoutes = {
	Root: '/district/:districtId',
	Dashboard: '/district/:districtId/dashboard',
	Engagements: {
		Root: '/district/:districtId/engagements',
		Details: '/engagements/:engagementId',
	},
	Families: {
		Root: '/district/:districtId/families',
		Detailes: '/families/:familyId',
	},
	Providers: {
		Root: '/district/:districtId/providers',
		Details: '/providers/:providerId',
	},
	CustomRequirements: '/district/:districtId/custom-requirements',
	Survey: '/district/:districtId/survey',
	UserManagment: '/district/:districtId/user-managment',
};
