import { EngagementDto, FamilyDto } from '@families-link/shared';
import { FC, useCallback, useMemo } from 'react';
import { CoreTableActionsCell, CellAction } from '../../../_core/components/_ui/core-table';
import { EngagementsTableHandlers } from './engagements-table.component';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { ArchiveIcon } from '../../../_core/constants/icons.constants';
export interface EngagementsTableActionsProps extends EngagementsTableHandlers {
	engagement: EngagementDto;
}

export const EngagementsTableActions: FC<EngagementsTableActionsProps> = (props) => {
	const { engagement, onDelete, onEdit, onView, onArchive, onSendEmail, onSendSMS, onUnarchive } = props;

	const handleDeleteEngagement = useCallback(() => {
		if (!engagement) {
			return;
		}

		onDelete?.(engagement);
	}, [onDelete, engagement]);

	const handleEditEngagement = useCallback(() => {
		if (!engagement) {
			return;
		}

		onEdit?.(engagement);
	}, [onEdit, engagement]);

	const handleViewEngagement = useCallback(() => {
		if (!engagement) {
			return;
		}

		onView?.(engagement);
	}, [onView, engagement]);

	const handleSendEmailEngagement = useCallback(() => {
		if (!engagement) {
			return;
		}

		onSendEmail?.(engagement);
	}, [onSendEmail, engagement]);

	const handleSendSMSEngagement = useCallback(() => {
		if (!engagement) {
			return;
		}

		onSendSMS?.(engagement);
	}, [onSendSMS, engagement]);

	const handleArchiveEngagement = useCallback(() => {
		if (!engagement) {
			return;
		}

		if (engagement.archivedAt) {
			onUnarchive?.(engagement);
		} else {
			onArchive?.(engagement);
		}
	}, [onArchive, onUnarchive, engagement]);

	const actions = useMemo<CellAction[]>(
		() => [
			{
				id: 'view',
				type: 'button',
				props: {
					children: <RemoveRedEyeOutlinedIcon color="primary" />,
					onClick: handleViewEngagement,
				},
			},
			{
				id: 'more',
				type: 'menu',
				props: {
					options: [
						{
							id: 'email',
							label: 'Share by email',
							icon: <SendOutlinedIcon color="primary" />,
							onClick: handleSendEmailEngagement,
							disabled: true,
						},
						{
							id: 'sms',
							label: 'Send SMS',
							icon: <ChatOutlinedIcon color="primary" />,
							onClick: handleSendSMSEngagement,
							disabled: true,
						},
						{
							id: 'archive',
							label: engagement.archivedAt ? 'Unarchive' : 'Archive',
							icon: <ArchiveIcon color="primary" />,
							onClick: handleArchiveEngagement,
						},
						{
							id: 'edit',
							label: 'Edit',
							icon: <DriveFileRenameOutlineOutlinedIcon color="primary" />,
							onClick: handleEditEngagement,
						},
						{
							id: 'delete',
							label: 'Delete',
							icon: <DeleteOutlineOutlinedIcon color="primary" />,
							onClick: handleDeleteEngagement,
						},
					],
				},
			},
		],
		[
			handleViewEngagement,
			handleEditEngagement,
			handleDeleteEngagement,
			handleSendEmailEngagement,
			handleSendSMSEngagement,
		]
	);

	return <CoreTableActionsCell actions={actions} />;
};
