import React, { FC, useCallback, useEffect } from 'react';
import { FieldError, FieldValues, ResolverOptions, useForm } from 'react-hook-form';
import { Box, MenuItem, Typography } from '@mui/material';
import { CoreModal, CoreModalProps } from '../../../_core/components/_ui/core-modal';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { UserRolesEnum } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import {
	containerButtonStyles,
	buttonStyles,
	leftButtonStyles,
	inputBoxStyles,
	modalBoxStyles,
	errorTextStyles,
} from './modal-form.styles';
import { CoreSelect, CoreSelectMenuItem } from '../../../_core/components/_ui/core-select';

export type UserFormModalProps = CoreModalProps & {
	formTitle: string;
	resolver: <TFieldValues extends FieldValues, TContext>(
		values: TFieldValues,
		context: TContext | undefined,
		options: ResolverOptions<TFieldValues>
	) => any;
	submitAction(values: any): Promise<void>;
	closeModal(): void;
	defaultValues?: any;
	formError?: any;
	submitButtonName?: string;
};

export const UserFormModal: FC<UserFormModalProps> = (props) => {
	const [t] = useTranslation();
	const { formTitle, resolver, submitAction, formError, closeModal, defaultValues, submitButtonName, ...modalProps } =
		props;

	const {
		register,
		handleSubmit,
		watch,
		setError,
		formState: { errors, isDirty, isLoading, isSubmitted, isValid },
	} = useForm({
		defaultValues,
		resolver,
		mode: 'all',
	});

	useEffect(() => {
		if (errors['role']) {
			setError('role', {
				type: 'manual',
				message: 'role is required',
			});
		}
	}, [setError, errors['role']]);

	const disableSubmit = !isValid && (isDirty || isSubmitted);

	const handleFormSubmit = useCallback(
		(values: any) => {
			submitAction(values);
		},
		[submitAction, setError]
	);

	return (
		<CoreModal {...modalProps} bodySx={{ pt: 0 }} paperSx={{ width: '100%' }}>
			<Box sx={modalBoxStyles}>
				<Typography variant="h5" sx={{ fontWeight: 'bold' }}>
					{formTitle}
				</Typography>
				<Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
					<Box sx={inputBoxStyles}>
						<CoreTextField
							label={t('users.forms.labels.name')}
							requiredMark
							fullWidth
							placeholder={t('users.forms.plaсeholders.name') ?? ''}
							{...register('name')}
							error={!!errors['name']}
							helperText={errors['name'] ? (errors['name'] as FieldError).message : undefined}
							controlSx={inputBoxStyles}
						/>
						<CoreTextField
							label={t('users.forms.labels.email')}
							requiredMark
							fullWidth
							placeholder={t('users.forms.plaсeholders.email') ?? ''}
							{...register('email')}
							error={!!errors['email']}
							helperText={errors['email'] ? (errors['email'] as FieldError).message : undefined}
							controlSx={inputBoxStyles}
						/>
						<CoreSelect
							value={watch('role') || ''}
							label={t('users.forms.labels.role')}
							requiredMark
							fullWidth
							placeholder={t('users.forms.plaсeholders.role') ?? ''}
							{...register('role')}
							error={!!errors['role']}
							helperText={errors['role'] ? (errors['role'] as FieldError).message : undefined}
						>
							{Object.keys(UserRolesEnum)
								.filter((key) => key !== 'SuperAdmin')
								.map((key) => (
									<CoreSelectMenuItem key={key} value={UserRolesEnum[key as keyof typeof UserRolesEnum].toString()}>
										{key}
									</CoreSelectMenuItem>
								))}
						</CoreSelect>
					</Box>
					{formError && (
						<Typography sx={errorTextStyles}>
							{formError?.data?.message ? formError.data.message : t('errors.invalid-credentials-error')}
						</Typography>
					)}
					<Box sx={containerButtonStyles}>
						<CoreButton onClick={closeModal} variant="secondary" sx={leftButtonStyles}>
							{t('users.forms.buttons.close-form')}
						</CoreButton>
						<CoreButton type="submit" disabled={disableSubmit} loading={isLoading} sx={buttonStyles}>
							{submitButtonName || t('users.forms.buttons.save')}
						</CoreButton>
					</Box>
				</Box>
			</Box>
		</CoreModal>
	);
};
