import { useMemo } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { FamilyDto, PermissionDto } from '@families-link/shared';
import { FamiliesTableHandlers } from '../../components/families-table/families-table.component';
import { FamiliesTableActions } from '../../components/families-table/families-table-actions.component';
import { getObjectLanguage, getObjectLanguageByKey } from '../../../_core/components/languages-component';
import { Avatar, Box, Typography } from '@mui/material';
import { useGetDistrictQuery } from '../../../../store/api/district.api';
import { DistrictCell } from '../../components/district-cell/district-cell.component';
const helper = createColumnHelper<FamilyDto & { createdAt: string; updatedAt: string }>();

export function useFamiliesTableColumns(
	handlers: FamiliesTableHandlers,
	permission?: PermissionDto
): ColumnDef<FamilyDto, string>[] {
	const { onDelete, onEdit, onView } = handlers;

	const [t] = useTranslation();

	const result = useMemo(
		() => [
			helper.accessor('fullName', {
				header: t('families.columns.full-name'),
				meta: {
					flex: 1,
				},
				cell: ({ getValue, row }) => {
					const imageUrl = row.original.imageUrl;
					const fullName = getValue();

					return (
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Avatar
								src={imageUrl}
								alt={fullName}
								style={{ width: 24, height: 24, marginRight: 18, borderRadius: '6px' }}
							/>
							<Typography sx={{ fontWeight: 'bold' }}>{fullName}</Typography>
						</Box>
					);
				},
			}) as ColumnDef<FamilyDto, string>,
			helper.accessor('email', {
				header: t('families.columns.email'),
				meta: {
					flex: 1,
				},
			}) as ColumnDef<FamilyDto, string>,
			helper.accessor('phoneNumber', {
				header: t('families.columns.phone-number'),
				meta: {
					flex: 1,
				},
			}) as ColumnDef<FamilyDto, string>,
			helper.accessor('location', {
				header: t('families.columns.location'),
				meta: {
					flex: 1,
				},
			}) as ColumnDef<FamilyDto, string>,
			helper.accessor('preferedLanguage', {
				header: t('families.columns.prefered-language'),
				meta: {
					flex: 1,
				},
				enableSorting: true,
				cell: (info) => {
					const option = getObjectLanguage(info.getValue());
					return option ? option.label : null;
				},
			}) as ColumnDef<FamilyDto, string>,
			...(permission?.read
				? [
						helper.accessor('districtId', {
							header: 'District',
							meta: {
								flex: 1,
							},
							cell: ({ getValue }) => {
								const districtId = getValue();
								return <DistrictCell districtId={districtId} />;
							},
						}) as ColumnDef<FamilyDto, string>,
				  ]
				: []),

			helper.display({
				id: 'actions',
				cell: (props) => (
					<FamiliesTableActions family={props.row.original} onDelete={onDelete} onEdit={onEdit} onView={onView} />
				),
			}) as ColumnDef<FamilyDto>,
		],
		[t, onDelete, onEdit]
	);

	return result;
}
