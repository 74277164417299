import { ProviderDto, OrderEnum } from '@families-link/shared';
import api from '.';

const providersApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getAllProviders: builder.query<
			ProviderDto[],
			{ languages?: string; sortBy?: string; sortOrder?: OrderEnum.ASC | OrderEnum.DESC; searchQuery?: string }
		>({
			query: ({ languages, sortBy, sortOrder, searchQuery }) => ({
				url: 'providers',
				params: { languages, sortBy, sortOrder, searchQuery },
			}),
		}),

		getAllProvidersByDistrictId: builder.query<
			ProviderDto[],
			{
				languages?: string;
				districtId?: string;
				sortBy?: string;
				sortOrder?: OrderEnum.ASC | OrderEnum.DESC;
				searchQuery?: string;
			}
		>({
			query: ({ languages, districtId, sortBy, sortOrder, searchQuery }) => ({
				url: 'providers/by/district',
				params: { languages, districtId, sortBy, sortOrder, searchQuery },
			}),
		}),

		createProvider: builder.mutation<ProviderDto, FormData>({
			query: (formData) => ({
				url: 'providers',
				method: 'POST',
				body: formData,
			}),
		}),

		updateProvider: builder.mutation<ProviderDto, { id: string; updateData: FormData }>({
			query: ({ id, updateData }) => ({
				url: `providers/${id}`,
				method: 'PUT',
				body: updateData,
			}),
		}),

		deleteProvider: builder.mutation<void, string>({
			query: (id) => ({
				url: `providers/${id}`,
				method: 'DELETE',
			}),
		}),

		getProviderById: builder.query<ProviderDto, string>({
			query: (id) => ({
				url: `providers/${id}`,
				method: 'GET',
			}),
		}),
	}),
	overrideExisting: false,
});

export default providersApi;

export const {
	useGetAllProvidersQuery,
	useGetAllProvidersByDistrictIdQuery,
	useCreateProviderMutation,
	useUpdateProviderMutation,
	useDeleteProviderMutation,
	useGetProviderByIdQuery,
} = providersApi;
