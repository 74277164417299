import { Length, IsPhoneNumber, IsEmail, IsEnum, IsNotEmpty, IsOptional } from 'class-validator';
import { LanguageEnum } from '../../enums';

export class UpdateFamilyDto {
	@IsNotEmpty()
	districtId: string;
	@Length(1, 100)
	fullName: string;
	@IsEmail()
	email: string;
	@IsPhoneNumber()
	phoneNumber: string;
	@Length(1)
	location: string;
	@IsEnum(LanguageEnum)
	preferedLanguage: string;
	@IsOptional()
	imageUrl?: string;
}
