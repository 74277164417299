import React, { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { CoreModal, CoreModalProps } from '../../../_core/components/_ui/core-modal';
import { CoreButton } from '../../../_core/components/_ui/core-button';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { CreateDistrictDto } from '@families-link/shared';
import { useTranslation } from 'react-i18next';
import { CoreTextField } from '../../../_core/components/_ui/core-textfield';
import {
	containerButtonStyles,
	buttonStyles,
	leftButtonStyles,
	inputBoxStyles,
	modalBoxStyles,
	errorTextStyles,
} from './create-form.styles';

export type CreateDistrictFormModalProps = CoreModalProps & {
	formError?: any;
	submitAction(values: CreateDistrictDto): Promise<void>;
	closeCreateModal(): void;
};

export const CreateDistrictFormModal: FC<CreateDistrictFormModalProps> = (props) => {
	const [t] = useTranslation();

	const { submitAction, formError, closeCreateModal, ...modalProps } = props;
	const resolver = classValidatorResolver(CreateDistrictDto);
	const defaultValues = { name: '' };

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty, isLoading, isSubmitted, isValid },
	} = useForm({
		defaultValues,
		resolver,
		mode: 'all',
	});

	const disableSubmit = !isValid && (isDirty || isSubmitted);

	const handleFormSubmit = useCallback(
		(values: CreateDistrictDto) => {
			submitAction(values);
		},
		[submitAction]
	);

	return (
		<CoreModal {...modalProps} bodySx={{ pt: 0 }} paperSx={{ width: '100%', maxWidth: '550px' }}>
			<Box sx={modalBoxStyles}>
				<Typography variant="h5" sx={{ fontWeight: 'bold' }}>
					{t('districts.forms.create-districts')}
				</Typography>
				<Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
					<Box sx={inputBoxStyles}>
						<CoreTextField
							label={t('districts.forms.labels.name')}
							requiredMark
							fullWidth
							placeholder={t('districts.forms.plaсeholders.name') ?? ''}
							{...register('name')}
							error={!!errors['name']}
							helperText={errors['name'] && errors['name']?.message}
						/>
					</Box>
					{formError && (
						<Typography sx={errorTextStyles}>
							{formError?.data?.message ? formError.data.message : t('errors.invalid-credentials-error')}
						</Typography>
					)}
					<Box sx={containerButtonStyles}>
						<CoreButton onClick={closeCreateModal} variant="secondary" sx={leftButtonStyles}>
							{t('districts.forms.buttons.close-form')}
						</CoreButton>
						<CoreButton type="submit" disabled={disableSubmit} loading={isLoading} sx={buttonStyles}>
							{t('districts.forms.buttons.create-district')}
						</CoreButton>
					</Box>
				</Box>
			</Box>
		</CoreModal>
	);
};
