import { useMemo } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { LanguageEnum, ProviderDto, WorkStatusEnum, ServiceTypeStatusEnum } from '@families-link/shared';

import { Verified, InHouse, Outsource } from '../../_core/constants/icons.constants';
import { Avatar, Typography, Box } from '@mui/material';
import { LanguageOptions, getObjectLanguage } from '../../_core/components/languages-component';
import { ProvidersTableHandlers } from '../components/providers-table';
import { ProvidersTableActions } from '../components/providers-table/providers-table-actions.component';

const helper = createColumnHelper<ProviderDto & { createdAt: string; updatedAt: string }>();

export function useProvidersTableColumns(handlers: ProvidersTableHandlers): ColumnDef<ProviderDto, string>[] {
	const { onView, onDelete, onEdit } = handlers;

	const [t] = useTranslation();

	const result = useMemo(
		() => [
			helper.accessor('fullName', {
				header: t('providers.full-name'),
				meta: {
					flex: 1.5,
				},
				enableSorting: true,
				cell: ({ getValue, row }) => {
					const imageUrl = row.original.imageUrl;
					const fullName = getValue();

					return (
						<Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
							<Avatar
								src={imageUrl}
								alt={fullName}
								style={{ width: 24, height: 24, marginRight: 18, borderRadius: '6px' }}
							/>
							<Typography
								sx={{
									fontWeight: 'bold',
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}
							>
								{fullName}
							</Typography>
						</Box>
					);
				},
			}) as ColumnDef<ProviderDto, string>,
			helper.accessor('targetLanguage', {
				header: t('providers.target-lang'),
				meta: {
					flex: 1,
				},
				enableSorting: true,
				cell: (info) => {
					const option = getObjectLanguage(info.getValue());
					return option ? option.label : null;
				},
			}) as ColumnDef<ProviderDto, string>,
			helper.accessor('verificationType', {
				header: t('providers.status'),
				meta: {
					flex: 0.8,
				},
				enableSorting: true,
				cell: (info) => (
					<Typography sx={{ display: 'flex', alignItems: 'center' }}>
						{info.getValue() ? (
							<>
								{t('providers.verified')} <Verified style={{ marginLeft: '8px' }} />
							</>
						) : (
							t('providers.no-verified')
						)}
					</Typography>
				),
			}) as ColumnDef<ProviderDto, string>,
			helper.accessor('workStatus', {
				header: t('providers.type'),
				meta: {
					flex: 0.8,
				},
				enableSorting: true,
				cell: (info) => {
					const value = info.getValue();

					let cellValue;

					switch (value) {
						case WorkStatusEnum.InHouse:
							cellValue = (
								<>
									<InHouse style={{ marginRight: '18px' }} />
									{t('providers.work-in-house')}
								</>
							);
							break;
						case WorkStatusEnum.Outsource:
							cellValue = (
								<>
									<Outsource style={{ marginRight: '18px' }} />
									{t('providers.work-outsource')}
								</>
							);
							break;
						default:
							cellValue = null;
					}

					return <Typography sx={{ display: 'flex', alignItems: 'center' }}>{cellValue}</Typography>;
				},
			}) as ColumnDef<ProviderDto, string>,
			helper.accessor('serviceType', {
				header: t('providers.service-type'),
				meta: {
					flex: 1.2,
				},
				enableSorting: true,
				cell: (info) => {
					const value = info.getValue();
					switch (value) {
						case ServiceTypeStatusEnum.Appointment:
							return (
								<Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
									{t('providers.appointment')}
								</Typography>
							);
						case ServiceTypeStatusEnum.Project:
							return (
								<Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
									{t('providers.project')}
								</Typography>
							);
						case ServiceTypeStatusEnum.Both:
							return (
								<Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
									{t('providers.appointment')}, {t('providers.project')}
								</Typography>
							);
						default:
							return null;
					}
				},
			}) as ColumnDef<ProviderDto, string>,
			helper.accessor('totalEngagements', {
				header: 'Total Engagements',
				meta: {
					flex: 1,
				},
				enableSorting: true,
				cell: ({ getValue }) => {
					const totalEngagement = getValue();
					return <Typography>{totalEngagement}</Typography>;
				},
			}) as ColumnDef<ProviderDto>,
			helper.display({
				id: 'actions',
				cell: (props) => (
					<ProvidersTableActions provider={props.row.original} onView={onView} onDelete={onDelete} onEdit={onEdit} />
				),
			}) as ColumnDef<ProviderDto>,
		],
		[t, onView, onDelete, onEdit]
	);

	return result;
}
