import React, { FC, ReactNode } from 'react';
import { Box, Theme } from '@mui/material';

interface IconCircleProps {
	danger?: boolean;
	children?: ReactNode;
	customBackgrondColorKey?: keyof Theme['colors'];
	customColorKey?: keyof Theme['colors'];
	size?: 'small' | 'normal';
}

export const IconCircle: FC<IconCircleProps> = (props) => {
	const { danger, children, customColorKey, customBackgrondColorKey, size = 'normal' } = props;

	return (
		<Box
			sx={[
				{
					width: size === 'small' ? 40 : 80,
					height: size === 'small' ? 40 : 80,
					display: 'flex',
					alignItems: 'center',
					color: (theme) => (customColorKey ? theme.colors[customColorKey] : theme.colors.white),
					justifyContent: 'center',
					borderRadius: '40px',
					backgroundColor: ({ colors }) =>
						customBackgrondColorKey ? colors[customBackgrondColorKey] : !danger ? colors.blue : colors.red,
				},
				({ breakpoints }) => ({
					[breakpoints.down('sm')]: {
						width: size === 'small' ? 30 : 60,
						height: size === 'small' ? 30 : 60,
						borderRadius: '30px',
					},
				}),
			]}
		>
			{children}
		</Box>
	);
};
